import React, { useState, useEffect } from "react";
import PageLayout from "../../components/Layout";
import Dialog from "../../components/Dialog";
import Drawer from "../../components/Drawer";
import UserForm from "../../components/UserForm";
import UserDrawer from "../../components/Drawer/UserDrawer";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { Table, Space, Tooltip, Button, Input } from "antd";
import { EyeOutlined, SearchOutlined, EditOutlined } from "@ant-design/icons";
import { getData, updateData } from "../../helper/api-calls";
import "./styles.css";

const Users = (props) => {
  const { title, breadcrumb } = props;
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState();
  const [searchedColumn, setSearchColumn] = useState("");
  const [searchText, setSearchText] = useState("");

  const [editData, setEditData] = useState();

  // state to toggle useEffect to refetch data from api
  const [refetch, setRefetch] = useState(0);

  // ------------------------------------------------ Visitor Data Initialization
  useEffect(() => {
    async function fetchData() {
      const url = "users/user";
      const response = await getData(url);
      if (!_.isEmpty(response)) {
        setUserData(response);
      }
      setLoading((loading) => !loading);
    }
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // ------------------------------------------------ Dialog Event
  const onDialogShow = () => {
    setShowDialog(!showDialog);
  };

  const onDialogDismiss = () => {
    setShowDialog(!showDialog);
  };

  const onDialogSubmit = (values, formData) => {
    onActionEdit(values, formData);
  };

  // ------------------------------------------------ Drawer Event
  const onDrawerShow = () => {
    setShowDrawer(!showDrawer);
  };

  const onDrawerDismiss = () => {
    setShowDrawer(!showDrawer);
  };

  // ----------------------------------------------- Search Event
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handlerSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handlerReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handlerSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlerSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handlerReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // ------------------------------------------------ CRUD Event

  /*
  // Edit the data of the specified user
  */
  const onActionEdit = (values, formData) => {
    const url = `users/update/` + values.user_id;
    onDialogDismiss();
    updateData(url, formData);
    setLoading((loading) => !loading);
    setRefetch(refetch + 1);
  };

  // ------------------------------------------------ Table Columns
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      render: (text, record) => (
        <Link to={`/users/${record.user_id}`}>{text}</Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      render: (text, record) => (
        <Link to={`/users/${record.user_id}`}>{text}</Link>
      ),
    },
    {
      title: "Registration Date",
      dataIndex: "updated_at",
      key: "updated_at",
      ...getColumnSearchProps("updated_at"),
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                onDrawerShow();
                setDrawerData(record);
              }}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditData(record);
                onDialogShow();
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb} loading={loading}>
      <div className="users-container">
        <Table
          columns={columns}
          dataSource={userData || []}
          rowKey={(record) => record.customer_id}
          loading={loading}
          bordered
          pagination={{ position: ["topRight", "bottomRight"] }}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Records:</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {_.size(userData)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
        <Dialog
          dialogTitle={"Edit User Details"}
          visibility={showDialog}
          onDismissDialog={onDialogDismiss}
        >
          <UserForm
            onFormDismiss={onDialogDismiss}
            onFormSubmit={onDialogSubmit}
            editData={editData || null}
          />
        </Dialog>
        <Drawer
          visibility={showDrawer}
          onClose={onDrawerDismiss}
          drawerTitle={"User Profile"}
          drawerWidth="600px"
        >
          <UserDrawer dataSource={drawerData || []} />
        </Drawer>
      </div>
    </PageLayout>
  );
};

export default Users;
