import React from "react";
import PromotionbannerContainer from "../../containers/StaticPage/PromotionBanner";

const PromotionBanner = () => {
  return (
    <div>
      <PromotionbannerContainer
        title="Promotion Banners"
        breadcrumb="Promotion Banners"
      />
    </div>
  );
};

export default PromotionBanner;
