import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/Layout";
import Drawer from "../../../components/Drawer";
import CompanyReport from "../../../components/Drawer/CompanyReportDrawer";
import FilterForm from "../../../components/FilterForm";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import { Table, Space, Tooltip, Button, Input, Row, Col } from "antd";
import { Bar } from "@ant-design/charts";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import "./styles.css";
import { getSpecifiedData } from "../../../helper/api-calls";

const CompanyReports = (props) => {
  const { title, breadcrumb } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState();
  const [searchedColumn, setSearchColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  // filter toggles the filter form
  const [filter, setFilter] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [chartData, setChartData] = useState([]);

  const [refetch, setRefetch] = useState(0);

  // ------------------------------------------------ EventReport Data Initialization
  useEffect(() => {
    async function fetchData() {
      const url = "companySales";
      const response = await getSpecifiedData(url);
      if (!_.isEmpty(response)) {
        processCompanyReportData(response);
      }
      setLoading((loading) => !loading);
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // Process data returned from API,
  // round sales & deposit values to two decimal
  const processCompanyReportData = (data) => {
    let processedData = data.map((data) => ({
      ...data,
      totalAmount: _.round(data.totalAmount, 2),
      totalDeposit: _.round(data.totalDeposit, 2),
      created_at: data.created_at.substring(0, 10),
      month: data.created_at.substring(5, 7),
      year: data.created_at.substring(0, 4),
    }));
    setData(processedData);
    setChartData(processedData);
  };

  // funcion to detect if the user clicked on Filter button
  const onFilter = () => {
    setFilter(!filter);
  };

  // ------------------------------------------------ Date Filter Form Submission
  const onActionSubmit = (values) => {
    setFiltered(!filtered);
    if (values.filter === "date") {
      var dateFilter = data.filter((record) => {
        return record.created_at.toString() === values.date.toString();
      });
      setData(dateFilter);
    } else if (values.filter === "month") {
      let value = values.date.substring(5, 7);
      var monthFilter = data.filter((record) => {
        return record.month.toString() === value.toString();
      });
      setData(monthFilter);
    } else if (values.filter === "year") {
      var yearFilter = data.filter((record) => {
        return record.year.toString() === values.date.toString();
      });
      setData(yearFilter);
    } else {
      var rangeFilter = data.filter((record) => {
        return (
          record.created_at.toString() >= values.date[0].toString() &&
          record.created_at.toString() <= values.date[1].toString()
        );
      });
      setData(rangeFilter);
    }
  };

  // Clear the date filter by refetching
  // complete set of data through API call
  const onActionClear = () => {
    setFilter(!filter);
    setFiltered(!filtered);
    setLoading(!loading);
    setRefetch(refetch + 1);
  };

  // ------------------------------------------------ Export CSV Event
  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const reportColumns = (results) => {
    if (!_.isEmpty(results)) {
      const columns = Object.keys(results[0]);

      let headers = [];

      columns.forEach((col, index) => {
        headers.push({ label: camelCase(col), key: col });
      });

      return headers;
    }
  };

  // ------------------------------------------------ Drawer Event
  const onDrawerShow = () => {
    setShowDrawer(!showDrawer);
  };

  const onDrawerDismiss = () => {
    setShowDrawer(!showDrawer);
  };

  // ----------------------------------------------- Search Event
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handlerSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handlerReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handlerSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlerSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handlerReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // ------------------------------------------------ Bar Chart Config
  const config = {
    data: chartData,
    xField: "totalAmount",
    yField: "company_name",
    height: 10000,
    legend: { position: "top-left" },
    barBackground: { style: { fill: "rgba(0,0,0,0.1)" } },
    meta: {
      totalAmount: { alias: "Total Sales" },
    },
    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
  };

  // ------------------------------------------------ Table Columns
  const columns = [
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      ...getColumnSearchProps("company_name"),
    },
    {
      title: "Total Sales",
      dataIndex: "totalAmount",
      key: "totalAmount",
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortDirection: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Deposit(RM)",
      dataIndex: "totalDeposit",
      key: "totalDeposit",
      sorter: (a, b) => a.totalDeposit - b.totalDeposit,
      sortDirection: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Orders",
      dataIndex: "totalSales",
      key: "totalSales",
      sorter: (a, b) => a.totalSales - b.totalSales,
      sortDirection: ["ascend", "descend"],
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                setDrawerData(record);
                onDrawerShow();
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb}>
      <div className="company-report-container">
        <Row justify="start">
          <Col className="action-col" span={3}>
            <Button type="primary" onClick={onFilter} disabled={loading}>
              Date Filter
            </Button>
          </Col>
          <Col className="action-col" span={5}>
            <CSVLink
              data={data}
              headers={reportColumns(data)}
              filename={"CompanyReport.csv"}
            >
              <Button type="primary" disabled={loading}>
                Export As CSV
              </Button>
            </CSVLink>
          </Col>
        </Row>

        {filter ? (
          <FilterForm
            onFormSubmit={onActionSubmit}
            onFormClear={onActionClear}
            onDataFiltered={filtered}
          />
        ) : null}
        <Table
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.company_id}
          loading={loading}
          pagination={{ position: ["topRight", "bottomRight"] }}
          scroll={{ x: 800 }}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Records:</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>{_.size(data)}</Table.Summary.Cell>
            </Table.Summary.Row>
          )}
          bordered
        />
        <Bar {...config} />
        <Drawer
          visibility={showDrawer}
          onClose={onDrawerDismiss}
          drawerTitle="Company Details"
          drawerWidth="1000px"
          destroyOnClose={true}
        >
          <CompanyReport dataSource={drawerData} />
        </Drawer>
      </div>
    </PageLayout>
  );
};

export default CompanyReports;
