import React from "react";
import EventReportContainer from "../../containers/Report/EventReport";

const EventReport = () => {
  return (
    <div>
      <EventReportContainer title="Event Report" breadcrumb="Event Report" />
    </div>
  );
};

export default EventReport;
