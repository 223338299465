import Dashboard from "../pages/dashboard";
import PageNotFound from "../pages/notfound";
import Admin from "../pages/admin";
import Companies from "../pages/companies/companies";
import Company from "../pages/companies/company/company";
import Event from "../pages/event";
import Manager from "../pages/manager";
import Notification from "../pages/notification";
import Order from "../pages/order";
import Redemption from "../pages/redemption";
import Users from "../pages/users/users";
import User from "../pages/users/user/user";
import CompanyReport from "../pages/Report/companyReport";
import CustomerReport from "../pages/Report/customerReport";
import EventReport from "../pages/Report/eventReport";
import CompletedRedemptionReport from "../pages/Report/completedRedemption";
import RemainingRedemptionReport from "../pages/Report/remainingRedemption";
import ZeroPurchaseReport from "../pages/Report/zeroPurchase";
import AboutUs from "../pages/StaticPages/about";
import TermsConditions from "../pages/StaticPages/termscondition";
import PrivacyPolicy from "../pages/StaticPages/privacypolicy";
import HomeBanner from "../pages/StaticPages/homepagebanner";
import GiftImage from "../pages/StaticPages/giftimage";
import EventInformation from "../pages/StaticPages/eventinformation";
import PromotionBanner from "../pages/StaticPages/promotionbanner";
import UserDataReport from "../pages/Report/userDataReport";

// ----------------------------------- Superadmin Routes
export const PrivateRoutes = [
  {
    key: "/dashboard",
    path: "/dashboard",
    component: Dashboard,
  },
  {
    key: "/admin",
    path: "/admin",
    component: Admin,
  },
  {
    key: "/companies",
    path: "/companies",
    component: Companies,
  },
  {
    key: "/companies/:id",
    path: "/companies/:id",
    component: Company,
  },
  {
    key: "/event",
    path: "/event",
    component: Event,
  },
  {
    key: "/manager",
    path: "/manager",
    component: Manager,
  },
  {
    key: "/notification",
    path: "/notification",
    component: Notification,
  },
  {
    key: "/order",
    path: "/order",
    component: Order,
  },
  {
    key: "/redemption",
    path: "/redemption",
    component: Redemption,
  },
  {
    key: "/users",
    path: "/users",
    component: Users,
  },
  {
    key: "/users/:id",
    path: "/users/:id",
    component: User,
  },
  {
    key: "/companyReport",
    path: "/companyReport",
    component: CompanyReport,
  },
  {
    key: "/customerReport",
    path: "/customerReport",
    component: CustomerReport,
  },
  {
    key: "/eventReport",
    path: "/eventReport",
    component: EventReport,
  },
  {
    key: "/userDataReport",
    path: "/userDataReport",
    component: UserDataReport,
  },
  {
    key: "/completedRedemption",
    path: "/completedRedemption",
    component: CompletedRedemptionReport,
  },
  {
    key: "/remainingRedemption",
    path: "/remainingRedemption",
    component: RemainingRedemptionReport,
  },
  {
    key: "/zeroPurchase",
    path: "/zeroPurchase",
    component: ZeroPurchaseReport,
  },
  {
    key: "/about",
    path: "/about",
    component: AboutUs,
  },
  {
    key: "/termsconditions",
    path: "/termsconditions",
    component: TermsConditions,
  },
  {
    key: "/privacypolicy",
    path: "/privacypolicy",
    component: PrivacyPolicy,
  },
  {
    key: "/homepagebanner",
    path: "/homepagebanner",
    component: HomeBanner,
  },
  {
    key: "/giftimage",
    path: "/giftimage",
    component: GiftImage,
  },
  {
    key: "/eventinformation",
    path: "/eventinformation",
    component: EventInformation,
  },
  {
    key: "/promotionbanner",
    path: "/promotionbanner",
    component: PromotionBanner,
  },
  {
    key: "/*",
    path: "/*",
    component: PageNotFound,
  },
];

export default { PrivateRoutes };
