import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import { Row, Col, Divider, Table, Input, Button, Space } from "antd";
import { Bar } from "@ant-design/charts";
import { SearchOutlined } from "@ant-design/icons";
import { getSpecifiedData } from "../../../helper/api-calls";
import "./styles.css";

const CompanyReportDrawer = (props) => {
  const { dataSource } = props;
  const data = dataSource || [];
  const [searchedColumn, setSearchColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);

  // ------------------------------------------------ API Calls
  // Get all event attended by the company,
  useEffect(() => {
    async function fetchData() {
      const url = `companyEventSales?company_id=` + data.company_id;
      const response = await getSpecifiedData(url);
      if (!_.isEmpty(response)) {
        setLoading((loading) => !loading);
        dataProcessing(response);
      }
    }
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
  //  Round data returned to 2 decimal places, caused by incorrect data structure in database
  */
  const dataProcessing = (rawData) => {
    var results = rawData.map((data) => ({
      ...data,
      totalAmount: _.round(data.totalAmount, 2),
      totalDeposit: _.round(data.totalDeposit, 2),
    }));
    setSalesData(results);
  };

  const DescriptionItem = ({ title, content }) => (
    <div className="description-item-wrapper">
      <Row justify="start" align="top">
        <Col span={7}>
          <p className="description-item-title">{title}:</p>
        </Col>
        <Col span={12}>{content}</Col>
      </Row>
    </div>
  );

  // ----------------------------------------------- Search Event
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handlerSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handlerReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handlerSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlerSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handlerReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // ------------------------------------------------ Bar Chart Config
  const config = {
    data: salesData,
    xField: "totalAmount",
    yField: "EventTitle",
    legend: { position: "top-left" },
    barBackground: { style: { fill: "rgba(0,0,0,0.1)" } },
    meta: {
      totalAmount: { alias: "Total Sales" },
    },
    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
  };

  // ------------------------------------------------ Table Columns
  const columns = [
    {
      title: "Event Title",
      dataIndex: "EventTitle",
      key: "EventTitle",
      ...getColumnSearchProps("EventTitle"),
    },
    {
      title: "Total Sales",
      dataIndex: "totalAmount",
      key: "totalAmount",
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortDirection: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Deposits",
      dataIndex: "totalDeposit",
      key: "totalDeposit",
      sorter: (a, b) => a.totalDeposit - b.totalDeposit,
      sortDirection: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Orders",
      dataIndex: "totalSales",
      key: "totalSales",
      sorter: (a, b) => a.totalSales - b.totalSales,
      sortDirection: ["ascend", "descend"],
    },
  ];

  return (
    <div className="user-drawer-container">
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Company Name"
            content={data.company_name || []}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Company Phone"
            content={data.office_no || []}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Company Total Sales(RM)"
            content={Number(data.totalAmount).toLocaleString("en") || []}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Company Total Deposit(RM)"
            content={Number(data.totalDeposit).toLocaleString("en") || []}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Company Total Orders"
            content={data.totalSales || []}
          />
        </Col>
      </Row>
      <Divider />
      <p className="section-title">Event</p>
      <Divider />
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={salesData}
            rowKey={(record) => record.EventID}
            loading={loading}
            bordered
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  Total Records:
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {_.size(salesData)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
      </Row>
      <Divider />
      <Bar {...config} />
    </div>
  );
};

export default CompanyReportDrawer;
