import React, { useState } from "react";
import {
  Form,
  DatePicker,
  Select,
  Divider,
  Row,
  Col,
  Button,
  Popconfirm,
  Space,
} from "antd";
import moment from "moment";
import "./styles.css";

const DateFilter = (props) => {
  const { Option } = Select;
  const [dateFilterForm] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [type, setType] = useState("date");

  const dateFormat = "YYYY-MM-DD";

  // todo: Complete data filtering by date functionality
  const PickerType = ({ type, onChange }) => {
    if (type === "date")
      return <DatePicker onChange={onChange} format={dateFormat} />;
    if (type === "range")
      return (
        <RangePicker
          onChange={onChange}
          format={dateFormat}
          ranges={{
            "This Week": [moment().startOf("week"), moment().endOf("week")],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
            "Last Week": [
              moment().subtract(1, "weeks").startOf("week"),
              moment().subtract(1, "weeks").endOf("week"),
            ],
            "Last Month": [
              moment().subtract(1, "months").startOf("month"),
              moment().subtract(1, "months").endOf("month"),
            ],
            "Last 6 Months": [
              moment().subtract(6, "months").startOf("month"),
              moment().subtract(1, "months").endOf("month"),
            ],
            "Last Year": [
              moment().subtract(1, "years").startOf("year"),
              moment().subtract(1, "years").endOf("year"),
            ],
          }}
          disabled={props.onDataFiltered}
        />
      );
    return (
      <DatePicker
        picker={type}
        onChange={onChange}
        disabled={props.onDataFiltered}
      />
    );
  };

  // clear date filtering on data
  const onActionClearFilter = () => {
    if (props.onFormClear) {
      props.onFormClear();
    }
  };

  const onActionSubmit = (values) => {
    if (type === "date") {
      const formValues = {
        ...values,
        date: values["date"].format("YYYY-MM-DD"),
        filter: type,
      };
      if (props.onFormSubmit) {
        props.onFormSubmit(formValues);
      }
    } else if (type === "month") {
      const formValues = {
        ...values,
        date: values["date"].format("YYYY-MM"),
        filter: type,
      };
      if (props.onFormSubmit) {
        props.onFormSubmit(formValues);
      }
    } else if (type === "year") {
      const formValues = {
        ...values,
        date: values["date"].format("YYYY"),
        filter: type,
      };
      if (props.onFormSubmit) {
        props.onFormSubmit(formValues);
      }
    } else {
      const rangeValues = values["date"];
      const formValues = {
        ...values,
        date: [
          rangeValues[0].format("YYYY-MM-DD"),
          rangeValues[1].format("YYYY-MM-DD"),
        ],
        filter: type,
      };
      if (props.onFormSubmit) {
        props.onFormSubmit(formValues);
      }
    }
  };

  return (
    <div className="date-filter-form">
      <Form
        form={dateFilterForm}
        onFinish={onActionSubmit}
        requiredMark={false}
      >
        <Row justify="start">
          <Col className="selection-col" xs={24} md={12} lg={4}>
            <Space size={12}>
              <Select
                value={type}
                onChange={setType}
                disabled={props.onDataFiltered}
              >
                <Option value="date">Date</Option>
                <Option value="month">Month</Option>
                <Option value="year">Year</Option>
                <Option value="range">Range</Option>
              </Select>
            </Space>
          </Col>
          <Col xs={24} md={12} lg={15}>
            <Space direction="horizontal" size={12}>
              <Form.Item
                name="date"
                label="Date Selection"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <PickerType type={type} disabled={props.onDataFiltered} />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Row justify="end">
          <Col className="reset-col" lg={3} md={24} xs={24}>
            <Popconfirm
              title="Clear date filter?"
              cancelText="No"
              okText="Yes"
              onConfirm={onActionClearFilter}
            >
              <Button block disabled={!props.onDataFiltered}>
                Clear
              </Button>
            </Popconfirm>
          </Col>
          <Col className="submit-col" lg={3} md={24} xs={24}>
            <Button
              type="primary"
              block
              htmlType="submit"
              disabled={props.onDataFiltered}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
    </div>
  );
};

export default DateFilter;
