import React from "react";
import { Form, Input, Divider, Button, Row, Col, Select } from "antd";
import "./styles.css";

const UserForm = (props) => {
  const { editData } = props;

  const { Option } = Select;

  // ------------------------------------------------ Form Handler
  const onActionDismiss = () => {
    if (props.onFormDismiss) {
      props.onFormDismiss();
    }
  };

  const onActionSubmit = (fieldsValue) => {
    let formData = new FormData();

    formData.append("race", fieldsValue.race);
    formData.append("userType", fieldsValue.userType);

    if (props.onFormSubmit) {
      props.onFormSubmit(fieldsValue, formData);
    }
  };

  return (
    <Form
      layout="vertical"
      preserve={false}
      onFinish={onActionSubmit}
      requiredMark={false}
    >
      <Form.Item
        name="user_id"
        label="User ID"
        initialValue={editData ? editData.user_id || null : []}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item name="userType" label="Account Type" initialValue="user">
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="name"
        label="Name"
        initialValue={editData ? editData.name || null : []}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="race"
        label="Race"
        rules={[
          { required: true, message: "Please pick a race for this user" },
        ]}
      >
        <Select placeholder="Select a race">
          <Option value="Malay">Malay</Option>
          <Option value="Chinese">Chinese</Option>
          <Option value="Indian">Indian</Option>
          <Option value="Others">Others</Option>
        </Select>
      </Form.Item>
      <Divider />
      <Row justify="end">
        <Col span={6} className="cancel-col">
          <Button size="large" block onClick={onActionDismiss}>
            Cancel
          </Button>
        </Col>
        <Col span={6}>
          <Button type="primary" size="large" block htmlType="submit">
            Confirm
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserForm;
