// import { useState, useEffect } from "react";
import axios from "axios";
import { setAuthorizationToken } from "../utils/common";
import { baseUrl } from "../utils/apiUrl";
import { invalidToken, errorResponse, errorRequest } from "../utils/error";
import { successNotification } from "../utils/api-success";

export const getData = (url) => {
  setAuthorizationToken();

  const response = axios
    .get(`${baseUrl()}/${url}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response ? error.response.status === 401 : null) {
        invalidToken();
      } else if (error.request) {
        errorResponse();
      } else {
        errorRequest();
      }
    });

  return response;
};

/*
//  Get (event, order, etc) image from the server
//  
*/
export const getImage = (url) => {
  setAuthorizationToken();

  const response = axios
    .get(`${baseUrl()}/${url}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response ? error.response.status === 401 : null) {
        invalidToken();
      } else if (error.request) {
        errorResponse();
      } else {
        errorRequest();
      }
    });

  return response;
};

/*
//  This function is reserved for API calls that requires
//  a specified resource, eg: (A specified company, user, order etc)
//  that requires the url to be appended with the unique identifier
*/
export const getSpecifiedData = (url) => {
  setAuthorizationToken();

  const response = axios
    .post(`${baseUrl()}/${url}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response ? error.response.status === 401 : null) {
        invalidToken();
      } else if (error.request) {
        errorResponse();
      } else {
        errorRequest();
      }
    });

  return response;
};

export const storeData = (url, formData) => {
  setAuthorizationToken();
  axios
    .post(`${baseUrl()}/${url}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then(() => {
      const successMsg = "Data created";
      const successDesc = "New data has been created!";
      successNotification(successMsg, successDesc);
    })
    .catch((error) => {
      if (error.response ? error.response.status === 401 : null) {
        invalidToken();
      } else if (error.request) {
        errorResponse();
      } else {
        errorRequest();
      }
    });
};

export const updateData = (url, formData) => {
  setAuthorizationToken();
  axios
    .post(`${baseUrl()}/${url}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then(() => {
      const successMsg = "Data updated";
      const successDesc = "The selected data has been updated!";
      successNotification(successMsg, successDesc);
    })
    .catch((error) => {
      if (error.response ? error.response.status === 401 : null) {
        invalidToken();
      } else if (error.request) {
        errorResponse();
      } else {
        errorRequest();
      }
    });
};

/*
//  API call to delete / inactivate specified data from database.
//  formdata variable is to pass specific identifier to the backend
*/
export const deleteData = (url, formData) => {
  setAuthorizationToken();

  const response = axios
    .post(`${baseUrl()}/${url}`, formData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then(() => {
      const msg = "Data deleted or inactivated";
      const desc = "The specified data has been deleted or inactivated";
      successNotification(msg, desc);
    })
    .catch((error) => {
      if (error.response ? error.response.status === 401 : null) {
        invalidToken();
      } else if (error.request) {
        errorResponse();
      } else {
        errorRequest();
      }
    });

  return response;
};

/*
 **  The commented code below is an attempt to introduce custom hooks
 **  to perform all REST API calls (POST, UPDATE, DELETE, GET)
 **  with only one asynchronus function. It is a work in progress,
 **  The code below returns a full html page as data, need debug
 **  to find out what's wrong
 **
 */

// export const useAxios = (axiosParams) => {
//   const [response, setResponse] = useState(undefined);
//   const [error, setError] = useState("");
//   const [loading, setLoading] = useState(true);

//   const fetchData = async (params) => {
//     try {
//       const result = await axios.request(params);
//       setResponse(result.data);
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData(axiosParams);
//   }, [axiosParams]);

//   return { response, error, loading };
// };
