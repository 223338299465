import React from "react";
import ZeroPurchaseContainer from "../../containers/Report/RedemptionReport/ZeroPurchase";

// This page is part of Redemption Report,
// displays user with no orders and no gift redemption

const ZeroPurchaseReport = () => {
  return (
    <div>
      <ZeroPurchaseContainer title="Zero Purchase" breadcrumb="Zero Purchase" />
    </div>
  );
};

export default ZeroPurchaseReport;
