import React, { useState } from "react";
import { Form, Divider, Button, Row, Col, Input } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
// import _ from "lodash";
import "./styles.css";

const BannerForm = (props) => {
  // const [disableUpload, setDisableUpload] = useState(true);
  const { editData } = props;
  const [selectedFile, setSelectedFile] = useState(null);

  // ------------------------------------------------ Form Handler
  const onActionDismiss = () => {
    if (props.onFormDismiss) {
      props.onFormDismiss();
    }
  };

  const onActionSubmit = (values) => {
    let formData = new FormData();
    formData.append("image", selectedFile);

    if (props.onFormSubmit) {
      props.onFormSubmit(values, formData, props.editMode);
    }
  };

  // ------------------------------------------------ Image Upload Handler
  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <Form
      layout="vertical"
      preserve={false}
      onFinish={onActionSubmit}
      requiredMark={false}
    >
      {props.editMode ? (
        <Form.Item
          name="image_id"
          label="ID"
          initialValue={editData ? editData.image_id || null : []}
        >
          <Input disabled />
        </Form.Item>
      ) : null}
      <Form.Item
        name="image"
        label="Promotion Banner"
        rules={[
          { required: true, message: "Please choose an image to upload!" },
        ]}
      >
        <input
          type="file"
          onChange={fileSelectedHandler}
          accept="image/jpg, image/png, image/jpeg"
        />
      </Form.Item>
      <Divider />
      <Row justify="end">
        <Col span={6} className="cancel-col">
          <Button size="large" block onClick={onActionDismiss}>
            Cancel
          </Button>
        </Col>
        <Col span={6}>
          <Button
            type="primary"
            size="large"
            block
            htmlType="submit"
            // disabled={disableUpload}
          >
            Upload
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BannerForm;
