import React, { useState, useEffect } from "react";
import PageLayout from "../../components/Layout";
import ActionButtons from "../../components/ActionButtons";
import Dialog from "../../components/Dialog";
import AdminForm from "../../components/AdminForm";
import _ from "lodash";
import { Table, Space, Button, Tooltip, Input } from "antd";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { getData, storeData, updateData } from "../../helper/api-calls";
import Highlighter from "react-highlight-words";
import "./styles.css";

const Admins = (props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchColumn] = useState("");

  const [refetch, setRefetch] = useState(0);

  const { title, breadcrumb, link } = props;

  // ------------------------------------------------- Admin Data Initialization
  useEffect(() => {
    async function fetchData() {
      const url = "users/admin";
      const response = await getData(url);
      if (!_.isEmpty(response)) {
        setData(response);
      }
      setLoading((loading) => !loading);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // ------------------------------------------------- Dialog Event
  const onDialogShow = () => {
    setShowDialog(!showDialog);
  };

  const onDialogDismiss = () => {
    if (editMode === true) {
      setEditMode(!editMode);
    }
    setShowDialog(!showDialog);
  };

  const onDialogSubmit = (values, formData, editMode) => {
    if (editMode === true) {
      onActionEdit(values, formData);
    } else {
      onActionCreate(formData);
    }
  };

  // ----------------------------------------------- Search Event
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handlerSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handlerReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handlerSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlerSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handlerReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // ----------------------------------------------- CRUD Event
  const onActionCreate = (formData) => {
    onDialogDismiss();
    const url = "users/admin";
    storeData(url, formData);
    setLoading((loading) => !loading);
    setRefetch(refetch + 1);
  };

  const onActionEdit = (values, formData) => {
    onDialogDismiss();
    const url = `users/update/` + values.id;
    updateData(url, formData);
    setLoading((loading) => !loading);
    setRefetch(refetch + 1);
  };

  // ----------------------------------------------- Table Columns
  const columns = [
    {
      title: "Name",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Email",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("username"),
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditMode(!editMode);
                setEditData(record);
                onDialogShow();
              }}
            >
              Edit
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <PageLayout
      headerTitle={title}
      breadcrumb={breadcrumb}
      link={link}
      loading={loading}
    >
      <ActionButtons
        createBtnTitle="Create Admin"
        onShowDialog={onDialogShow}
      />
      <div className="admin-content-box">
        <Table
          columns={columns}
          dataSource={data || []}
          rowKey={(record) => record.id}
          loading={loading}
          bordered
          pagination={{ position: ["topRight", "bottomRight"] }}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Records:</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>{_.size(data)}</Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </div>
      {editMode ? (
        <Dialog
          dialogTitle="Edit Admin"
          visibility={showDialog}
          onDismissDialog={onDialogDismiss}
        >
          <AdminForm
            editMode={editMode}
            onFormDismiss={onDialogDismiss}
            onFormSubmit={onDialogSubmit}
            editData={editData || []}
          />
        </Dialog>
      ) : (
        <Dialog
          dialogTitle="Create New Admin"
          visibility={showDialog}
          onDismissDialog={onDialogDismiss}
        >
          <AdminForm
            editMode={editMode}
            onFormDismiss={onDialogDismiss}
            onFormSubmit={onDialogSubmit}
          />
        </Dialog>
      )}
    </PageLayout>
  );
};

export default Admins;
