import React from "react";
import CompaniesContainer from "../../containers/Companies";

const Companies = () => {
  return (
    <div>
      <CompaniesContainer title={"Companies"} breadcrumb="Companies" />
    </div>
  );
};

export default Companies;
