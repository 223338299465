import React from "react";
import ManagerContainer from "../containers/Manager";

// #Note: Manager here refers to redemption manager

const Manager = () => {
  return (
    <div>
      <ManagerContainer
        title={"Redemption Managers"}
        breadcrumb="Redemption Managers"
      />
    </div>
  );
};

export default Manager;
