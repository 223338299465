import React, { useState, useEffect } from "react";
import PageLayout from "../../../../components/Layout";
import FilterForm from "../../../../components/FilterForm";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import { getData } from "../../../../helper/api-calls";
import { CSVLink } from "react-csv";
import { Table, Space, Button, Input, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./styles.css";

const ZeroPurchase = (props) => {
  const { title, breadcrumb } = props;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  // filter is to toggle filter form
  const [filter, setFilter] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState(false);

  const [refetch, setRefetch] = useState(0);

  // API Call to get Users with no Orders
  useEffect(() => {
    async function fetchData() {
      const url = "zeroPurchase";
      const response = await getData(url);
      if (!_.isEmpty(response)) {
        setLoading((loading) => !loading);
        dataProcessing(response);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // process date data to allow date filtering
  const dataProcessing = (data) => {
    let processedData = data.map((data) => ({
      ...data,
      created_at: data.created_at.substring(0, 10),
      month: data.created_at.substring(5, 7),
      year: data.created_at.substring(0, 4),
    }));
    setData(processedData);
  };

  // funcion to detect if the user clicked on Filter button
  const onFilter = () => {
    setFilter(!filter);
  };

  // ------------------------------------------------ Date Filter Form Submission
  const onActionSubmit = (values) => {
    setFiltered(!filtered);

    if (values.filter === "date") {
      var dateFilter = data.filter((record) => {
        return record.created_at.toString() === values.date.toString();
      });
      setData(dateFilter);
    } else if (values.filter === "month") {
      let value = values.date.substring(5, 7);
      var monthFilter = data.filter((record) => {
        return record.month.toString() === value.toString();
      });
      setData(monthFilter);
    } else if (values.filter === "year") {
      var yearFilter = data.filter((record) => {
        return record.year.toString() === values.date.toString();
      });
      setData(yearFilter);
    } else {
      var rangeFilter = data.filter((record) => {
        return (
          record.created_at.toString() >= values.date[0].toString() &&
          record.created_at.toString() <= values.date[1].toString()
        );
      });
      setData(rangeFilter);
    }
  };

  // Clear the date filter by refetching
  // complete set of data through API call
  const onActionClear = () => {
    setLoading(!loading);
    setFilter(!filter);
    setFiltered(!filtered);
    setRefetch(refetch + 1);
  };

  // ------------------------------------------------ Export CSV Event
  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const reportColumns = (results) => {
    if (!_.isEmpty(results)) {
      const columns = Object.keys(results[0]);

      let headers = [];

      columns.forEach((col, index) => {
        headers.push({ label: camelCase(col), key: col });
      });

      return headers;
    }
  };

  // ----------------------------------------------- Search Event
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handlerSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handlerReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handlerSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlerSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handlerReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // ------------------------------------------------ Table Columns
  const columns = [
    {
      title: "User",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Contact No",
      dataIndex: "hp_no",
      key: "hp_no",
      ...getColumnSearchProps("hp_no"),
    },
    // {
    //   title: "Total Points",
    //   dataIndex: "totalPoints",
    //   key: "totalPoints",
    //   ...getColumnSearchProps("totalPoints"),
    // },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb} loading={loading}>
      <div className="zeropurchase-report-container">
        <Row justify="start">
          <Col className="action-col" span={3}>
            <Button type="primary" onClick={onFilter} disabled={loading}>
              Date Filter
            </Button>
          </Col>
          <Col className="action-col" span={5}>
            <CSVLink
              data={data}
              headers={reportColumns(data)}
              filename={"ZeroPurchase.csv"}
            >
              <Button type="primary" disabled={loading}>
                Export As CSV
              </Button>
            </CSVLink>
          </Col>
        </Row>

        {filter ? (
          <FilterForm
            onFormSubmit={onActionSubmit}
            onFormClear={onActionClear}
            onDataFiltered={filtered}
          />
        ) : null}
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ position: ["topRight", "bottomRight"] }}
          rowKey={(record) => record.user_id}
          loading={loading}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Records:</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>{_.size(data)}</Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </div>
    </PageLayout>
  );
};

export default ZeroPurchase;
