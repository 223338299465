import axios from "axios";

// get token to determine if user is authenticated
export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

// get user role to implement RBAC
export const getUserRole = () => {
  return sessionStorage.getItem("role") || "undefined";
};

// get the username to display in page header
export const getUsername = () => {
  return sessionStorage.getItem("username") || "placeholder";
};

export const getUser = () => {
  return {
    user: sessionStorage.getItem("user") || "undefined",
    password: sessionStorage.getItem("password") || "undefined",
  };
};

export const setUserSessionToken = (token) => {
  sessionStorage.setItem("token", token);
};

export const setUsername = (username) => {
  sessionStorage.setItem("username", JSON.stringify(username));
};

export const setUser = (user, password) => {
  sessionStorage.setItem("user", JSON.stringify(user));
  sessionStorage.setItem("password", JSON.stringify(password));
};

export const setUserRole = (userRole) => {
  sessionStorage.setItem("role", userRole);
};

export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("username");
};

export const removeUserRole = () => {
  sessionStorage.removeItem("role");
};

export const removeUser = () => {
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("password");
};

export const decimalOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

/* get token from session storage, append the token to axios's
// default headers to avoid attaching token manually on 
// every axios request
*/
export const setAuthorizationToken = () => {
  const token = `Bearer ${sessionStorage.getItem("token") || null}`;
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }
};

/*
//  Redirect the user when token has expired
*/
export const redirect = () => {
  sessionStorage.clear();
  window.location.replace("/");
};
