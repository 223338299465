import React from "react";
import UserContainer from "../../../containers/Users/User";

const User = () => {
  return (
    <div>
      <UserContainer title="User" nestedCrumb="Users" link={"/users"} />
    </div>
  );
};

export default User;
