import React from "react";
import PageLayout from "../../components/Layout";
import { Form, Input, Button, Divider } from "antd";
import "./styles.css";

// todo: add push notification functionality

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const Notification = (props) => {
  const { title, breadcrumb } = props;

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success: ", values);
    } catch (errorInfo) {
      console.log("Failed: ", errorInfo);
    }
  };

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb}>
      <div className="notification-container">
        <Form form={form} name="push-notification-form">
          <Form.Item
            {...formItemLayout}
            name="subject"
            label="Subject"
            rules={[
              {
                required: true,
                message: "Subject cannot be blank",
              },
            ]}
          >
            <Input placeholder="Please enter the subject" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Description cannot be blank",
              },
            ]}
          >
            <TextArea
              rows={5}
              placeholder="Please enter the description"
              autoSize={{ minRows: 4, maxRows: 6 }}
            />
          </Form.Item>
          <Divider />
          <Form.Item>
            <Button type="primary" onClick={onCheck}>
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>
    </PageLayout>
  );
};

export default Notification;
