import React, { useState, useEffect } from "react";
import PageLayout from "../../components/Layout";
import ActionButtons from "../../components/ActionButtons";
import Dialog from "../../components/Dialog";
import CompanyForm from "../../components/CompanyForm";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import {
  getData,
  storeData,
  updateData,
  deleteData,
} from "../../helper/api-calls";
import { Table, Space, Button, Tooltip, Popconfirm, Input } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "./styles.css";

// todo: disable CUD functionality for users other than superadmin

const Companies = (props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchColumn] = useState("");

  const [refetch, setRefetch] = useState(0);

  const { title, breadcrumb } = props;

  // ----------------------------------------------- Company Data Initialization
  useEffect(() => {
    async function fetchData() {
      const url = "company";
      const response = await getData(url);
      if (!_.isEmpty(response)) {
        setData(response);
      }
      setLoading((loading) => !loading);
    }
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // ----------------------------------------------- Dialog Event
  const onDialogShow = () => {
    setShowDialog(!showDialog);
  };

  const onDialogDismiss = () => {
    if (editMode === true) {
      setEditMode(!editMode);
    }
    setShowDialog(!showDialog);
  };

  const onDialogSubmit = (values, formData, editMode) => {
    if (editMode === true) {
      onActionEdit(values, formData);
    } else {
      onActionCreate(formData);
    }
  };

  // ----------------------------------------------- Search Event
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handlerSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handlerReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handlerSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlerSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handlerReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // ----------------------------------------------- CRUD Event
  const onActionCreate = (formData) => {
    onDialogDismiss();
    const url = "company";
    storeData(url, formData);
    setLoading((loading) => !loading);
    setRefetch(refetch + 1);
  };

  const onActionEdit = (record, formData) => {
    onDialogDismiss();
    const url = `company/update/` + record.CompanyID;
    updateData(url, formData);
    setLoading(!loading);
    setRefetch(refetch + 1);
  };

  /*
  //  Delete function does not delete record from DB,
  //  only changes the status of the company to Defunct
  */
  const onActionDelete = (record) => {
    const url = "company/delete/" + record.company_id;
    deleteData(url);
    setLoading(!loading);
    setRefetch(refetch + 1);
  };

  // ----------------------------------------------- Table Columns
  const columns = [
    {
      title: "Company Name",
      dataIndex: "CompanyName",
      key: "CompanyName",
      ...getColumnSearchProps("CompanyName"),
      render: (text, record) => (
        <Link to={`/companies/${record.company_id}`}>{text}</Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "PIC",
      key: "PIC",
      ...getColumnSearchProps("PIC"),
    },
    {
      title: "Company Contact",
      dataIndex: "office_no",
      key: "office_no",
      ...getColumnSearchProps("office_no"),
    },
    {
      title: "Company Status",
      dataIndex: "status",
      key: "status",
      render: (record) => {
        if (record === 1) {
          return "ACTIVE";
        } else if (record === 0) {
          return "DEFUNCT";
        } else {
          return "N/A";
        }
      },
      filters: [
        { text: "Defunct", value: 0 },
        { text: "Active", value: 1 },
        { text: "N/A", value: "N/A" },
      ],
      onFilter: (value, record) =>
        record.status ? record.status.toString().includes(value) : [],
    },
    {
      title: "Actions",
      key: "acion",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="Edit Company Detail">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditMode(!editMode);
                setEditData(record);
                onDialogShow();
              }}
              type="primary"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => {
                onActionDelete(record);
              }}
              okText="Yes"
              icon={<WarningOutlined style={{ color: "red" }} />}
            >
              <Button icon={<DeleteOutlined />} danger />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb} loading={loading}>
      <ActionButtons
        createBtnTitle="Create Company Account"
        onShowDialog={onDialogShow}
        loading={loading}
      />
      <div className="companies-container">
        <Table
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.CompanyCode}
          loading={loading}
          bordered
          pagination={{ position: ["topRight", "bottomRight"] }}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Records:</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>{_.size(data)}</Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
        {editMode ? (
          <Dialog
            dialogTitle="Edit Company Account"
            visibility={showDialog}
            onDismissDialog={onDialogDismiss}
          >
            <CompanyForm
              editMode={editMode}
              onFormDismiss={onDialogDismiss}
              onFormSubmit={onDialogSubmit}
              editData={editData || []}
            />
          </Dialog>
        ) : (
          <Dialog
            dialogTitle="Create New Company"
            visibility={showDialog}
            onDismissDialog={onDialogDismiss}
            dialogWidth="650px"
          >
            <CompanyForm
              editMode={editMode}
              onFormDismiss={onDialogDismiss}
              onFormSubmit={onDialogSubmit}
              companyData={data}
            />
          </Dialog>
        )}
      </div>
    </PageLayout>
  );
};

export default Companies;
