import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/Layout";
import Drawer from "../../../components/Drawer";
import CompanyReportDrawer from "../../../components/Drawer/CompanyReportDrawer";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { Table, Button, Space, Tooltip } from "antd";
import { Bar } from "ant-design-pro/lib/Charts";
import { EyeOutlined } from "@ant-design/icons";
import "./styles.css";
import { getSpecifiedData } from "../../../helper/api-calls";

const Company = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  let { id } = useParams();
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState([]);
  const [companyEventSales, setCompanyEventSales] = useState([]);

  const { title, nestedCrumb, link } = props;

  //  todo: refactor api calls to reduce code repetition

  // ------------------------------------------------ Company Data Initialization
  useEffect(() => {
    async function fetchData() {
      const url = `companyEventSales?company_id=${id}`;
      const response = await getSpecifiedData(url);
      if (!_.isEmpty(response)) {
        processData(response);
        setChartData(response);
      }
      setLoading((loading) => !loading);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Format any monetary data into two decimal places
  const processData = (data) => {
    let processedData = data.map((data) => ({
      ...data,
      totalAmount: _.round(data.totalAmount, 2),
      totalDeposit: _.round(data.totalDeposit, 2),
    }));
    setData(processedData);
  };

  const setChartData = (data) => {
    let companyEventData = data.map((data) => ({
      x: data.StartDt,
      y: _.round(data.totalAmount, 2),
    }));
    setCompanyEventSales(companyEventData);
  };

  // ------------------------------------------------ Drawer Event
  const onDrawerShow = () => {
    setShowDrawer(!showDrawer);
  };

  const onDrawerDismiss = () => {
    setShowDrawer(!showDrawer);
  };

  // ------------------------------------------------ Table Columns
  const columns = [
    {
      title: "Company name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Office Phone",
      dataIndex: "office_no",
      key: "office_no",
    },
    {
      title: "Total Sales(RM)",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Deposit(RM)",
      dataIndex: "totalDeposit",
      key: "totalDeposit",
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Orders",
      dataIndex: "totalSales",
      key: "totalSales",
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                setDrawerData(record);
                onDrawerShow();
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <PageLayout
      headerTitle={title}
      nestedCrumb={nestedCrumb}
      breadcrumb={id}
      link={link}
      loading={loading}
    >
      <div className="company-container">
        <Table
          className="company-table"
          columns={columns}
          dataSource={data || []}
          loading={loading}
          rowKey={(record) => record.company_id}
          bordered
        />
        <Bar height={400} title="Company Sales" data={companyEventSales} />
        <Drawer
          visibility={showDrawer}
          onClose={onDrawerDismiss}
          drawerTitle="Company Sales Detail"
          drawerWidth="900px"
          destroyOnClose={true}
        >
          <CompanyReportDrawer dataSource={drawerData} />
        </Drawer>
      </div>
    </PageLayout>
  );
};

export default Company;
