import React from "react";
import CompanyReportContainer from "../../containers/Report/CompanyReport";

const CompanyReport = () => {
  return (
    <div>
      <CompanyReportContainer
        title="Company Report"
        breadcrumb="Company Report"
      />
    </div>
  );
};

export default CompanyReport;
