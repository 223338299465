import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/Layout";
import _ from "lodash";
import { getData } from "../../../helper/api-calls";
import { CSVLink } from "react-csv";
import { Table, Button, Row, Col } from "antd";
import "./styles.css";

const UserDataReports = (props) => {
  const { title, breadcrumb } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  // ------------------------------------------------ EventReport Data Initialization
  useEffect(() => {
    async function fetchData() {
      const url = "user-summary";
      const response = await getData(url);
      if (!_.isEmpty(response)) {
        setData(response);
      }
      setLoading((loading) => !loading);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ------------------------------------------------ Export CSV Event
  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const reportColumns = (data) => {
    if (!_.isEmpty(data)) {
      const columns = Object.keys(data[0]);

      let headers = [];

      columns.forEach((col, index) => {
        if (col !== "month" && col !== "year") {
          headers.push({ label: camelCase(col), key: col });
        }
      });

      return headers;
    }
  };

  // ------------------------------------------------ Table Columns
  const columns = [
    {
      title: "Total Users",
      dataIndex: "total_users",
      key: "total_users",
    },
    {
      title: "Total Names",
      dataIndex: "total_name",
      key: "total_name",
    },
    {
      title: "Total Email",
      dataIndex: "total_email",
      key: "total_email",
    },
    {
      title: "Total HP",
      dataIndex: "total_hp",
      key: "total_hp",
    },
    {
      title: "Total Gender",
      dataIndex: "total_gender",
      key: "total_gender",
    },
    {
      title: "Total Races",
      dataIndex: "total_race",
      key: "total_race",
    },
    {
      title: "Total State",
      dataIndex: "total_state",
      key: "total_state",
    },
    {
      title: "Total City",
      dataIndex: "total_city",
      key: "total_city",
    },
    {
      title: "Total IC",
      dataIndex: "total_ic",
      key: "total_ic",
    },
    {
      title: "Total DOB",
      dataIndex: "total_dob",
      key: "total_dob",
    },
    {
      title: "Total Age",
      dataIndex: "total_age",
      key: "total_age",
    },
  ];

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb} loading={loading}>
      <div className="userdata-report-container">
        <Row justify="start">
          <Col className="action-col" span={5}>
            <CSVLink
              data={data}
              headers={reportColumns(data)}
              filename={"UserDataReport.csv"}
            >
              <Button type="primary" disabled={loading}>
                Export As CSV
              </Button>
            </CSVLink>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={data || []}
          loading={loading}
          rowKey={(record) => record.total_users}
          bordered
          pagination={{ position: ["topRight", "bottomRight"] }}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Records:</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>{_.size(data)}</Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </div>
    </PageLayout>
  );
};

export default UserDataReports;
