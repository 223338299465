import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/Layout";
import _ from "lodash";
import ActionButtons from "../../../components/ActionButtons";
import Dialog from "../../../components/Dialog";
import StaticPageForm from "../../../components/StaticPageForm";
import draftToHtml from "draftjs-to-html";
import { getData, storeContent } from "./api";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.css";

const PrivacyPolicies = (props) => {
  const { title, breadcrumb } = props;
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showDialog, setShowDialog] = useState(false);
  const [pageName, setPageName] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [refetch, setRefetch] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await getData();
      if (!_.isEmpty(response)) {
        getHtmlPage(response);
      }
    }
    fetchData();
  }, [refetch]);

  const getHtmlPage = (items) => {
    const html = items[0].content;

    const pageName = items[0].page_name;

    const blocksFromHTML = convertFromHTML(html);

    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    setEditorState(EditorState.createWithContent(content));
    setPageName(pageName);

    setLoading((loading) => !loading);
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  /* Converts content in editor to HTML format,
  // assign it to htmlContent state, the state 
  // is passed to the form as props
  */
  const convertToHtml = () => {
    if (!_.isEmpty(editorState)) {
      setHtmlContent(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );
    }
  };

  // ------------------------------------------------ Dialog Event
  const onDialogShow = () => {
    convertToHtml();
    setShowDialog(!showDialog);
  };

  const onDialogDismiss = () => {
    setShowDialog(!showDialog);
  };

  const onDialogSubmit = (values) => {
    onActionCreate(values);
  };

  // ------------------------------------------------ Create Action
  const onActionCreate = (values) => {
    setLoading((loading) => !loading);
    onDialogDismiss();
    storeContent(values);
    setRefetch(refetch + 1);
  };

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb} loading={loading}>
      <ActionButtons
        createBtnTitle="Save Content"
        onShowDialog={onDialogShow}
        disabled={loading}
      />
      <div className="privacy-policy-container">
        <div className="text-editor">
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
        </div>
        <Dialog
          dialogTitle="Privacy Policy"
          visibility={showDialog}
          onDismissDialog={onDialogDismiss}
        >
          <StaticPageForm
            onFormDismiss={onDialogDismiss}
            onFormSubmit={onDialogSubmit}
            pageContent={htmlContent || null}
            pageName={pageName || null}
          />
        </Dialog>
      </div>
    </PageLayout>
  );
};

export default PrivacyPolicies;
