import React from "react";
import EventContainer from "../containers/Event";

const Event = () => {
  return (
    <div>
      <EventContainer title="Events" breadcrumb="Events" />
    </div>
  );
};

export default Event;
