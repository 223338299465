import React, { useState, useEffect } from "react";
import PageLayout from "../../components/Layout";
import Drawer from "../../components/Drawer";
import OrderDrawer from "../../components/Drawer/OrderDrawer";
import Dialog from "../../components/Dialog";
import OrderForm from "../../components/OrderForm";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import { getData, deleteData, updateData } from "../../helper/api-calls";
import { Table, Space, Button, Tooltip, Input, Popconfirm, Tag } from "antd";
import {
  EyeOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import "./styles.css";

const Orders = (props) => {
  const { title, breadcrumb } = props;
  const [orderData, setOrderData] = useState();
  const [loading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [drawerData, setDrawerData] = useState();
  const [searchedColumn, setSearchColumn] = useState("");
  const [searchText, setSearchText] = useState("");

  // state to toggle useEffect to refetch data
  const [refetch, setRefetch] = useState(0);

  // todo: Dynamically assign category filters

  /*
      Each order can have multiple redemptions.
      Data returned may contain duplicate order_id,
      but different Redemption ID
  */

  // ------------------------------------------------ Order Data Initialization
  useEffect(() => {
    async function fetchData() {
      const url = "order";
      const response = await getData(url);
      if (!_.isEmpty(response)) {
        setLoading((loading) => !loading);
        setOrderData(response);
      }
    }
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // ------------------------------------------------ Dialog Event
  const onDialogShow = () => {
    setShowDialog(!showDialog);
  };

  const onDialogDismiss = () => {
    setShowDialog(!showDialog);
  };

  const onDialogSubmit = (values) => {
    onActionEdit(values);
  };

  // ------------------------------------------------ Drawer Event
  const onDrawerShow = () => {
    setShowDrawer(!showDrawer);
  };

  const onDrawerDismiss = () => {
    setShowDrawer(!showDrawer);
  };

  // ----------------------------------------------- Search Event
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handlerSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handlerReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handlerSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlerSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handlerReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // ------------------------------------------------- Filter Handler
  // Function to check for NULL values in Redemption Status column
  // Convert the NULL object into string to allow data filtering with redemption status
  // const onFilterRedemption = (value, record) => {
  //   if (record.redeemStatus !== null) {
  //     return record.redeemStatus.includes(value);
  //   } else {
  //     // convert Null into string then return the filtered results
  //     var results = JSON.stringify(record.redeemStatus);
  //     return results.includes(value);
  //   }
  // };

  // ------------------------------------------------ CRUD Event
  const onActionEdit = (values, formData) => {
    onDialogDismiss();
    const url = `txn/update/` + values.order_id;
    updateData(url, formData);
    setLoading(!loading);
    setRefetch(refetch + 1);
  };

  // orders not deleted, merely changing status to cancelled (status = 2)
  const onActionDelete = (record) => {
    const url = `order/delete/` + record.order_id;
    setLoading(!loading);
    deleteData(url);
    setRefetch(refetch + 1);
  };

  // ------------------------------------------------ Table Columns
  const columns = [
    {
      title: "Customer Name",
      dataIndex: "user_name",
      key: "user_name",
      width: 100,
      fixed: "left",
      ...getColumnSearchProps("user_name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      fixed: "left",
      width: 150,
      ...getColumnSearchProps("email"),
    },
    {
      title: "HP No",
      dataIndex: "hp",
      key: "hp",
      ...getColumnSearchProps("hp"),
    },
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
      ...getColumnSearchProps("company_name"),
    },
    {
      title: "Sales Person",
      dataIndex: "sales_person",
      key: "sales_person",
      ...getColumnSearchProps("sales_person"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      filters: [
        { text: "Furniture", value: "Furniture" },
        { text: "Cash & Carry", value: "Cash & Carry" },
        { text: "Electrical", value: "Electrical" },
        { text: "Renovation", value: "Renovation" },
        { text: "Kitchen & Bath", value: "Kitchen & Bath" },
      ],
      onFilter: (value, record) =>
        record.remarks ? record.remarks.includes(value) : [],
    },
    {
      title: "Amount (RM)",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Deposit (RM)",
      dataIndex: "deposit_amount",
      key: "deposit_amount",
      sorter: (a, b) => a.deposit_amount - b.deposit_amount,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "created_at",
      ...getColumnSearchProps("created_at"),
    },
    {
      title: "Order Status",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
      render: (value) => {
        if (value === "1" || value === 1) {
          return <Tag color="orange">Pending</Tag>;
        } else if (value === "2" || value === 2) {
          return <Tag color="red">Cancelled</Tag>;
        } else if (value === "3" || value === 3) {
          return <Tag color="green">Redeemed</Tag>;
        } else {
          return <Tag color="yellow">Pending</Tag>;
        }
      },
    },
    {
      title: "Event",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title"),
    },
    {
      title: "Points Collected",
      dataIndex: "deposit_amount",
      key: "deposit_amount",
      sorter: (a, b) => a.deposit_amount - b.deposit_amount,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Points Redeemed",
      dataIndex: "redeem_points",
      key: "redeem_points",
      sorter: (a, b) => a.redeem_points - b.redeem_points,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Actions",
      key: "acion",
      fixed: "right",
      width: 150,
      render: (record) => (
        <Space size="middle">
          <Tooltip title="View Details">
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                setDrawerData(record);
                onDrawerShow();
              }}
            ></Button>
          </Tooltip>
          <Tooltip title="Edit Details">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditData(record);
                onDialogShow();
              }}
            ></Button>
          </Tooltip>
          <Tooltip title="Cancel Order">
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => {
                onActionDelete(record);
              }}
              okText="Yes"
              cancelText="No"
              icon={<WarningOutlined style={{ color: "red" }} />}
            >
              <Button icon={<DeleteOutlined />} danger />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb}>
      <div className="order-container">
        <Table
          columns={columns}
          dataSource={orderData || []}
          loading={loading}
          scroll={{ x: 1300 }}
          bordered
          pagination={{ position: ["topRight", "bottomRight"] }}
          rowKey={(record) => record.order_id}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Records:</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {_.size(orderData)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
        <Drawer
          visibility={showDrawer}
          onClose={onDrawerDismiss}
          drawerTitle="Order Details"
          drawerWidth="900px"
          destroyOnClose={true}
        >
          <OrderDrawer dataSource={drawerData || []} />
        </Drawer>
        <Dialog
          dialogTitle="Edit Order Details"
          visibility={showDialog}
          onDismissDialog={onDialogDismiss}
        >
          <OrderForm
            onFormDismiss={onDialogDismiss}
            onFormSubmit={onDialogSubmit}
            editData={editData || []}
          />
        </Dialog>
      </div>
    </PageLayout>
  );
};

export default Orders;
