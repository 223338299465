import React from "react";
import { Form, Input, Divider, Button, Row, Col } from "antd";
import "./styles.css";

const AdminForm = (props) => {
  const { editData } = props;

  const onActionDismiss = () => {
    if (props.onFormDismiss) {
      props.onFormDismiss();
    }
  };

  const onActionSubmit = (values) => {
    let formData = new FormData();
    formData.append("username", values.username);
    formData.append("email", values.email);
    formData.append("userType", values.userType);
    formData.append("password", values.password);

    if (props.onFormSubmit) {
      props.onFormSubmit(values, formData, props.editMode);
    }
  };

  return (
    <Form
      layout="vertical"
      preserve={false}
      onFinish={onActionSubmit}
      requiredMark={false}
    >
      {props.editMode ? (
        <Form.Item
          name="id"
          label="ID"
          initialValue={editData ? editData.id || null : []}
        >
          <Input disabled />
        </Form.Item>
      ) : null}
      <Form.Item
        name="username"
        label="Email"
        initialValue={editData ? editData.username || null : []}
        rules={[
          {
            required: true,
            message: "Admin name cannot be blank!",
          },
          {
            type: "email",
            message: "Please enter a valid email address",
          },
        ]}
      >
        <Input placeholder="Please enter admin name" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Name"
        initialValue={editData ? editData.email || null : []}
        rules={[
          {
            required: true,
            message: "Username cannot be blank!",
          },
        ]}
      >
        <Input placeholder="Please enter admin email" />
      </Form.Item>
      <Form.Item name="userType" label="Account Type" initialValue="admin">
        <Input disabled />
      </Form.Item>
      {props.editMode ? (
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              min: 8,
              message: "Password must be 8 characters or more",
            },
          ]}
        >
          <Input.Password placeholder="Enter admin password" />
        </Form.Item>
      ) : (
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Password cannot be blank!",
            },
            {
              min: 8,
              message: "Password must be 8 characters or more",
            },
          ]}
        >
          <Input.Password placeholder="Enter admin password" />
        </Form.Item>
      )}
      <Divider />
      <Row justify="end">
        <Col span={6} className="cancel-col">
          <Button size="large" block onClick={onActionDismiss}>
            Cancel
          </Button>
        </Col>
        <Col span={6}>
          <Button type="primary" size="large" block htmlType="submit">
            Confirm
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AdminForm;
