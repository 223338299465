import React from "react";
import { Row, Col, Divider } from "antd";
import "./styles.css";

const UserDrawer = (props) => {
  const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
      <Row justify="start" align="top">
        <Col span={7}>
          <p className="site-description-item-profile-p-label">{title}:</p>
        </Col>
        <Col span={12}>{content}</Col>
      </Row>
    </div>
  );

  const { dataSource } = props;

  const data = dataSource || [];

  return (
    <div className="user-drawer-container">
      <Row>
        <Col span={12}>
          <DescriptionItem title="Name" content={data.name || []} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Email" content={data.email || []} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="IC No" content={data.ic_no || "N/A"} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="HP No" content={data.hp_no || "N/A"} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="City" content={data.city || "N/A"} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="State" content={data.state || "N/A"} />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Address"
            content={data.delivery_address || "N/A"}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem title="DOB" content={data.dob || "N/A"} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Age" content={data.age || "N/A"} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Race" content={data.race || "N/A"} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Gender" content={data.gender || "N/A"} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Points" content={data.points || "N/A"} />
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default UserDrawer;
