import React from "react";
import CompanyContainer from "../../../containers/Companies/Company";

const Company = () => {
  return (
    <div>
      <CompanyContainer
        title={"Companies"}
        nestedCrumb="Companies"
        link={"/companies"}
      />
    </div>
  );
};

export default Company;
