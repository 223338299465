import React from "react";
import OrderContainer from "../containers/Order";

const Order = () => {
  return (
    <div>
      <OrderContainer title="Orders" breadcrumb="Orders" />
    </div>
  );
};

export default Order;
