import React, { useState } from "react";
import {
  Row,
  Col,
  PageHeader,
  Layout,
  Form,
  Button,
  Input,
  Divider,
  notification,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  setUserSessionToken,
  setUsername,
  setUserRole,
  setUser,
} from "../../../utils/common";
import { baseUrl } from "../../../utils/apiUrl";
import "./styles.css";

// todo: hash password data before sending it to back-end

const Login = () => {
  const { Content } = Layout;

  const [loading, setLoading] = useState(false);

  const onActionSubmit = (values) => {
    // -------------------------------------------- Login POST Action
    axios
      .post(
        `${baseUrl()}/auth/login`,
        {
          email: values.email,
          password: values.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setLoading(!loading);
        setUserSessionToken(response.data.access_token);
        setUser(values.email, values.password);
        // ---------------------------------------- Request to get current user username

        axios
          .post(
            `${baseUrl()}/auth/me`,
            {
              email: values.email,
              password: values.password,
            },
            {
              headers: {
                authorization: `Bearer ${response.data.access_token[0]}`,
              },
            }
          )
          .then((response) => {
            setUsername(response.data.username);
            setUserRole(response.data.roleID);
            window.location.replace("/dashboard");
          })
          .catch((error) => {
            if (error.response ? error.response.status === 401 : null) {
              const title = "API Error";
              const description = "Authentication by middleware failed!";
              errorNotification(title, description);
            } else {
              const title = "Unknown error";
              const description = "Please contact support";
              errorNotification(title, description);
            }
          });
      })
      .catch((error) => {
        setLoading(!loading);
        if (error.response ? error.response.status === 401 : null) {
          const title = "Authentication Error";
          const description = "Either email or password is incorrect!";
          errorNotification(title, description);
        } else {
          const title = "Unknown error";
          const description = "Please contact support";
          errorNotification(title, description);
        }
      });
  };

  const errorNotification = (title, description) => {
    const key = "error";
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key);
        }}
      >
        Ok
      </Button>
    );
    notification["error"]({
      message: title,
      description: description,
      key,
      btn,
    });
  };

  return (
    <Layout className="login-layout">
      <Content>
        <div className="login-form-container">
          <Row justify="center" align="middle">
            <Col xs={24} md={12} lg={8}>
              <div className="login-page-header">
                <PageHeader title="HOMEs" />
              </div>
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col className="login-header-col" xs={24} md={12} lg={8}>
              <div className="login-form-header">
                <PageHeader title="Sign In" />
              </div>
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col xs={24} md={12} lg={8}>
              <div className="login-form">
                <Form
                  name="login-form"
                  className="login-form"
                  onFinish={onActionSubmit}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Email cannot be blank!",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="form-item-icon" />}
                      placeholder="Email"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Password cannot be blank!",
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<LockOutlined className="form-item-icon" />}
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Divider />
                  <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                      Sign In
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default Login;
