import React from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { PageHeader, Breadcrumb, Row, Col } from "antd";
import "./styles.css";

// The header for each page, should be customized depending on currently active page

// todo: dynamically render breadcrumbs & link breadcrumbs to their respective page

const ContentHeader = (props) => {
  const { contentHeaderTitle, nestedCrumb, breadcrumb, link } = props;

  return (
    <div className="pages-header">
      <Row justify="space-between" align="middle">
        <Col>
          <PageHeader title={contentHeaderTitle} />
        </Col>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="/dashboard">Home</a>
            </Breadcrumb.Item>
            {!isEmpty(nestedCrumb) ? (
              <Breadcrumb.Item>
                <Link to={link}>{nestedCrumb}</Link>
              </Breadcrumb.Item>
            ) : null}
            <Breadcrumb.Item>{breadcrumb}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
    </div>
  );
};

export default ContentHeader;
