import React, { useState } from "react";
import moment from "moment";
import {
  Form,
  Input,
  InputNumber,
  Divider,
  Button,
  Row,
  Col,
  DatePicker,
} from "antd";
import "./styles.css";

// todo: refactor code for date picker, remove conditional rendering (if possible)
// todo: refactor code for Upload

const EventForm = (props) => {
  const { editData, editMode } = props;
  const dateFormat = "YYYY-MM-DD";
  const [selectedFile, setSelectedFile] = useState(null);

  // ------------------------------------------------ Form Handler
  const onActionDismiss = () => {
    if (props.onFormDismiss) {
      props.onFormDismiss();
    }
  };

  const onActionSubmit = (fieldsValue) => {
    let formData = new FormData();

    // format the date value before submitting the request
    const values = {
      ...fieldsValue,
      StartDate: fieldsValue["StartDate"].format("YYYY-MM-DD"),
      EndDate: fieldsValue["EndDate"].format("YYYY-MM-DD"),
    };

    if (editMode) {
      appendUpdateData(values, formData);
    } else {
      appendCreateData(values, formData);
    }

    if (props.onFormSubmit) {
      props.onFormSubmit(values, formData, editMode);
    }
  };

  // append data into form to store new data
  const appendCreateData = (values, formData) => {
    formData.append("title", values.title);
    formData.append("location", values.location);
    formData.append("date", values.StartDate);
    formData.append("end_date", values.EndDate);
    formData.append("status", values.status);
    formData.append("image", selectedFile);
  };

  // append data into form to update existing data
  const appendUpdateData = (values, formData) => {
    formData.append("title", values.title);
    formData.append("location", values.location);
    formData.append("date", values.StartDate);
    formData.append("end_date", values.EndDate);
    formData.append("status", values.status);
    formData.append("image", selectedFile);
  };

  // ------------------------------------------------ Upload Handler
  const fileSelectedHanlder = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <Form
      layout="vertical"
      preserve={false}
      onFinish={onActionSubmit}
      requiredMark={false}
    >
      {editMode ? (
        <Form.Item
          name="exhibition_id"
          label="ID"
          initialValue={editData ? editData.exhibition_id || null : []}
        >
          <Input disabled />
        </Form.Item>
      ) : null}
      <Form.Item
        name="title"
        label="Event Title"
        initialValue={editData ? editData.title || null : []}
        rules={[
          {
            required: true,
            message: "Event title cannot be blank!",
          },
        ]}
      >
        <Input placeholder="Please enter event title" />
      </Form.Item>
      <Form.Item
        name="location"
        label="Event Location"
        initialValue={editData ? editData.location || null : []}
        rules={[{ required: true, message: "Event location cannot be empty!" }]}
      >
        <Input placeholder="Please enter event location" />
      </Form.Item>
      {editMode ? (
        <Form.Item
          name="StartDate"
          label="Start Date"
          initialValue={moment(
            editData ? editData.StartDate || null : [],
            dateFormat
          )}
          rules={[
            {
              required: true,
              message: "Event start date cannot be empty!",
            },
          ]}
        >
          <DatePicker
            showTime
            placeholder="Select start date"
            format={dateFormat}
          />
        </Form.Item>
      ) : (
        <Form.Item
          name="StartDate"
          label="Start Date"
          rules={[
            { required: true, message: "Event start date cannot be empty!" },
          ]}
        >
          <DatePicker
            showTime
            placeholder="Select start date"
            format={dateFormat}
          />
        </Form.Item>
      )}
      {editMode ? (
        <Form.Item
          name="EndDate"
          label="End Date"
          initialValue={moment(
            editData ? editData.EndDate || null : [],
            dateFormat
          )}
          rules={[
            { required: true, message: "Event end date cannot be empty!" },
          ]}
        >
          <DatePicker
            showTime
            placeholder="Select end date"
            format={dateFormat}
          />
        </Form.Item>
      ) : (
        <Form.Item
          name="EndDate"
          label="End Date"
          rules={[
            { required: true, message: "Event end date cannot be empty!" },
          ]}
        >
          <DatePicker
            showTime
            placeholder="Select end date"
            format={dateFormat}
          />
        </Form.Item>
      )}
      <Form.Item
        name="image"
        label="Event Image"
        rules={[
          { required: true, message: "Please choose an image to upload!" },
        ]}
      >
        <input
          type="file"
          onChange={fileSelectedHanlder}
          accept="image/jpg, image/png, image/jpeg"
        />
      </Form.Item>
      <Form.Item name="status" label="Event Status" initialValue={1}>
        <InputNumber disabled />
      </Form.Item>
      <Divider />
      <Row justify="end">
        <Col span={6} className="cancel-col">
          <Button size="large" block onClick={onActionDismiss}>
            Cancel
          </Button>
        </Col>
        <Col span={6}>
          <Button type="primary" size="large" block htmlType="submit">
            Confirm
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EventForm;
