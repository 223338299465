import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Login from "./pages/login";
import PublicRoute from "./components/Route/PublicRoute";
import PrivateRoute from "./components/Route/PrivateRoute";
import { PrivateRoutes } from "./config/routes";
import "antd/dist/antd.css";
import "ant-design-pro/dist/ant-design-pro.css";

const App = () => {
  // useState cannot be used to maintain logged in state, as it is not persistent

  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/" component={Login} />
        {PrivateRoutes.map((page) => (
          <PrivateRoute
            exact
            key={page.key}
            path={page.path}
            component={page.component}
          />
        ))}
      </Switch>
    </Router>
  );
};

export default App;
