import React from "react";
import AdminContainer from "../containers/Admin";

const Admin = () => {
  return (
    <div>
      <AdminContainer title="Admins" breadcrumb="Admin" link=""/>
    </div>
  );
};

export default Admin;
