import React, { PureComponent } from "react";
import { Layout } from "antd";
import "./styles.css";

export default class Footer extends PureComponent {
  render() {
    const { Footer } = Layout;
    return (
      <div className="page-footer">
        <Footer>Copyright © 2019-2020 HOMEs. All rights reserved</Footer>
      </div>
    );
  }
}
