import React from "react";
import EventInformationContainer from "../../containers/StaticPage/EventInformation";

const EventInformation = () => {
  return (
    <div>
      <EventInformationContainer
        title="Event Information"
        breadcrumb="Event Information"
      />
    </div>
  );
};

export default EventInformation;
