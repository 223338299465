import React, { useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import { getSpecifiedData } from "../../../helper/api-calls";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Col, Divider, Table, Input, Space, Button } from "antd";
import { Bar } from "@ant-design/charts";
import "./styles.css";

const EventReportDrawer = (props) => {
  const { dataSource } = props;
  const data = dataSource || [];
  const [searchedColumn, setSearchColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [dailySalesData, setDailySalesData] = useState([]);
  const [raceSalesData, setRaceSalesData] = useState([]);
  const [categorySalesData, setCategorySalesData] = useState([]);

  // ------------------------------------------------ API Calls
  // ------------------------- Get TotalSales, TotalDeposits, TotalOrders of every company that attended an event,
  useEffect(() => {
    async function fetchData() {
      const url = `eventSalesDetail?exhibition_id=` + data.exhibition_id;
      const response = await getSpecifiedData(url);
      if (!_.isEmpty(response)) {
        setSalesData(response);
        setDailySalesData(response.daily_sales);
        setRaceSalesData(response.race_sales);
        setCategorySalesData(response.category_sales);
      }
      setLoading((loading) => !loading);
    }
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DescriptionItem = ({ title, content }) => (
    <div className="description-item-wrapper">
      <Row justify="start" align="top">
        <Col span={7}>
          <p className="description-item-title">{title}:</p>
        </Col>
        <Col span={12}>{content}</Col>
      </Row>
    </div>
  );

  // ----------------------------------------------- Search Event
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handlerSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handlerReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handlerSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlerSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handlerReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // ------------------------------------------------ Table Columns
  const companyColumns = [
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      ...getColumnSearchProps("company_name"),
    },
    // {
    //   title: "Company Category",
    //   dataIndex: "category",
    //   key: "category",
    //   filters: [
    //     // { text: "Furniture", value: "Furniture" },
    //     // { text: "Cash & Carry", value: "Cash & Carry" },
    //     // { text: "Electrical", value: "Electrical" },
    //     // { text: "Renovation", value: "Renovation" },
    //     {
    //       text: "Kitchen Cabinet & Wardrobe",
    //       value: "Kitchen Cabinet & Wardrobe",
    //     },
    //     { text: "Floor Finishes", value: "Floor Finishes" },
    //     { text: "Garden & Landscape", value: "Garden & Landscape" },
    //     { text: "Gate/Awning", value: "Gate/Awning" },
    //     { text: "Home Security System", value: "Home Security System" },
    //     { text: "Smarthome System", value: "Smarthome System" },
    //     { text: "Switches", value: "Switches" },
    //     { text: "Home Services", value: "Home Services" },
    //     { text: "Interior Design", value: "Interior Design" },
    //     { text: "Kitchen & Bath", value: "Kitchen & Bath" },
    //     { text: "Kitchenware & Houseware", value: "Kitchenware & Houseware" },
    //     { text: "Swimming Pool", value: "Swimming Pool" },
    //     { text: "Window, Door & Glass", value: "Window, Door & Glass" },
    //     { text: "Art & Decorative", value: "Art & Decorative" },
    //     { text: "Furniture & Furnishing", value: "Furniture & Furnishing" },
    //     { text: "AV & Home Appliances", value: "AV & Home Appliances" },
    //     { text: "Household/Cash & Carry", value: "Household/Cash & Carry" },
    //     { text: "Kids Furniture", value: "Kids Furniture" },
    //     { text: "Mattress & Bedding Set", value: "Mattress & Bedding Set" },
    //     { text: "Insect & Security Screen", value: "Insect & Security Screen" },
    //     { text: "Soft Furnishing", value: "Soft Furnishing" },
    //     { text: "Water Filtration & Solar", value: "Water Filtration & Solar" },
    //     { text: "Lighting", value: "Lighting" },
    //     { text: "Wall Finishes", value: "Wall Finishes" },
    //     { text: "Others", value: "Others" },
    //   ],
    //   onFilter: (value, record) =>
    //     record.category ? record.category.includes(value) : [],
    // },
    {
      title: "Total Sales",
      dataIndex: "total_amount",
      key: "total_amount",
      sorter: (a, b) => a.total_amount - b.total_amount,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Deposits",
      dataIndex: "total_deposit",
      key: "total_deposit",
      sorter: (a, b) => a.total_deposit - b.total_deposit,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Orders",
      dataIndex: "total_orders",
      key: "total_orders",
      sorter: (a, b) => a.total_orders - b.total_orders,
      sortDirections: ["ascend", "descend"],
    },
  ];

  // -------------------------- Catrgory Table Columns
  const categoryColumns = [
    {
      title: "Sales Category",
      dataIndex: "remarks",
      key: "remarks",
      filters: [
        { text: "Furniture", value: "Furniture" },
        { text: "Cash & Carry", value: "Cash & Carry" },
        { text: "Electrical", value: "Electrical" },
        { text: "Renovation", value: "Renovation" },
        { text: "Kitchen & Bath", value: "Kitchen & Bath" },
      ],
      onFilter: (value, record) =>
        record.remarks ? record.remarks.includes(value) : [],
    },
    {
      title: "Total Sales(RM)",
      dataIndex: "total_category_amount",
      key: "total_category_amount",
      sorter: (a, b) => a.total_category_amount - b.total_category_amount,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Sales(RM)",
      dataIndex: "total_category_deposit",
      key: "total_category_deposit",
      sorter: (a, b) => a.total_category_deposit - b.total_category_deposit,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Orders",
      dataIndex: "total_category_orders",
      key: "total_category_orders",
      sorter: (a, b) => a.total_category_orders - b.total_category_orders,
      sortDirections: ["ascend", "descend"],
    },
  ];

  const dailyTotalColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Total Sales(RM)",
      dataIndex: "total_daily_sales",
      key: "total_daily_sales",
      sorter: (a, b) => a.total_daily_sales - b.total_daily_sales,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Deposit(RM)",
      dataIndex: "total_daily_deposit",
      key: "total_daily_deposit",
      sorter: (a, b) => a.total_daily_deposit - b.total_daily_deposit,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Orders",
      dataIndex: "total_daily_orders",
      key: "total_daily_orders",
      sorter: (a, b) => a.total_daily_orders - b.total_daily_orders,
      sortDirections: ["ascend", "descend"],
    },
  ];

  const raceSalesColumns = [
    {
      title: "Race",
      dataIndex: "race",
      key: "race",
    },
    {
      title: "Total Sales(RM)",
      dataIndex: "total_race_sales",
      key: "total_race_sales",
      sorter: (a, b) => a.total_race_sales - b.total_race_sales,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Deposit(RM)",
      dataIndex: "total_race_deposit",
      key: "total_race_deposit",
      sorter: (a, b) => a.total_race_deposit - b.total_race_deposit,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Orders",
      dataIndex: "total_race_orders",
      key: "total_race_orders",
      sorter: (a, b) => a.total_race_orders - b.total_race_orders,
      sortDirections: ["ascend", "descend"],
    },
  ];

  const redemptionColumns = [
    {
      title: "Tier 1",
      dataIndex: "Tier1",
      key: "Tier1",
    },
    {
      title: "Tier 2",
      dataIndex: "Tier2",
      key: "Tier2",
    },
    {
      title: "Tier 3",
      dataIndex: "Tier3",
      key: "Tier3",
    },
    {
      title: "Tier 4",
      dataIndex: "Tier4",
      key: "Tier4",
    },
    {
      title: "Tier 5",
      dataIndex: "Tier5",
      key: "Tier5",
    },
    {
      title: "Tier 6",
      dataIndex: "Tier6",
      key: "Tier6",
    },
  ];

  // ------------------------------------------------ Bar Chart Config

  /*
  //  Bar graph configuration for Daily Sales of an event
  */
  const dailySales = {
    data: dailySalesData,
    xField: "total_daily_sales",
    yField: "date",
    legend: { position: "top-left" },
    barBackground: { style: { fill: "rgba(0,0,0,0.1)" } },
    meta: {
      totalAmount: { alias: "Total Sales" },
    },
    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
  };

  /*
  //  Bar graph configuration for Total Sales of an event
  //  according to racial profiles
  */
  const raceSales = {
    data: raceSalesData,
    xField: "total_race_sales",
    yField: "race",
    legend: { position: "top-left" },
    barBackground: { style: { fill: "rgba(0,0,0,0.1)" } },
    meta: {
      totalAmount: { alias: "Total Sales" },
    },
    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
  };

  const categorySales = {
    data: categorySalesData,
    xField: "total_category_amount",
    yField: "category_name",
    legend: { position: "top-left" },
    barBackground: { style: { fill: "rgba(0,0,0,0.1)" } },
    meta: {
      totalAmount: { alias: "Total Sales" },
    },
    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
  };

  return (
    <div className="user-drawer-container">
      <Row>
        <Col span={12}>
          <DescriptionItem title="Title" content={data.title || []} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Location" content={data.location || []} />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Event Total Sales(RM)"
            content={data.totalAmount || []}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Event Total Deposit(RM)"
            content={data.totalDeposit || []}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Event Total Orders"
            content={data.totalSales || []}
          />
        </Col>
      </Row>
      <Divider />
      <p className="section-title">Company Total Sales</p>
      <Divider />
      <Row>
        <Col span={24}>
          <Table
            columns={companyColumns}
            dataSource={salesData.company_sales || []}
            rowKey={(record) => record.company_name}
            loading={loading}
            bordered
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  Total Records:
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {_.size(salesData.company_sales)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
      </Row>
      <Divider />
      <p className="section-title">Daily Total Sales</p>
      <Divider />
      <Row>
        <Col span={24}>
          <Table
            columns={dailyTotalColumns}
            dataSource={salesData.daily_sales || []}
            rowKey={(record) => record.date}
            loading={loading}
            bordered
          />
        </Col>
        <Col span={24}>
          <Bar {...dailySales} />
        </Col>
      </Row>
      <Divider />
      <p className="section-title">Categories</p>
      <Divider />
      <Row>
        <Col span={24}>
          <Table
            columns={categoryColumns}
            dataSource={salesData.category_sales || []}
            rowKey={(record) => record.remarks}
            loading={loading}
            bordered
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  Total Records:
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {_.size(salesData.category_sales)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
        <Col span={24}>
          <Bar {...categorySales} />
        </Col>
      </Row>
      <Divider />
      <p className="section-title">Race Total Sales</p>
      <Divider />
      <Row>
        <Col span={24}>
          <Table
            columns={raceSalesColumns}
            dataSource={salesData.race_sales || []}
            rowKey={(record) => record.race}
            bordered
            loading={loading}
          />
        </Col>
        <Col span={24}>
          <Bar {...raceSales} />
        </Col>
      </Row>
      <Divider />
      <p className="section-title">Redemptions</p>
      <Divider />
      <Row>
        <Col span={24}>
          <Table
            columns={redemptionColumns}
            dataSource={salesData.redemption_tier}
            rowKey={(record) => record.event_id}
            bordered
            loading={loading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EventReportDrawer;
