import axios from "axios";
import { setAuthorizationToken } from "../../../utils/common";
import { baseUrl } from "../../../utils/apiUrl";
import {
  invalidToken,
  errorResponse,
  errorRequest,
} from "../../../utils/error";
import { successNotification } from "../../../utils/api-success";

export const getData = () => {
  setAuthorizationToken();

  const response = axios
    .get(`${baseUrl()}/about_us`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response ? error.response.status === 401 : null) {
        invalidToken();
      } else if (error.request) {
        errorResponse();
      } else {
        errorRequest();
      }
    });

  return response;
};

export const storeContent = (values) => {
  setAuthorizationToken();
  axios
    .post(
      `${baseUrl()}/about_us`,
      {
        page_name: values.page_name,
        content: values.content,
        image: values.image,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    )
    .then(() => {
      const successMsg = "Page Updated";
      const successDesc = "About Us page has been updated!";
      successNotification(successMsg, successDesc);
    })
    .catch((error) => {
      if (error.response ? error.response.status === 401 : null) {
        invalidToken();
      } else if (error.request) {
        errorResponse();
      } else {
        errorRequest();
      }
    });
};
