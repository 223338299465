import React from "react";
import DashboardContainer from "../containers/Dashboard";

const Dashboard = () => {
  return (
    <div>
      <DashboardContainer title={"Dashboard"} />
    </div>
  );
};

export default Dashboard;
