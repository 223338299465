import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/Layout";
import ActionButtons from "../../../components/ActionButtons";
import Dialog from "../../../components/Dialog";
import BannerForm from "../../../components/BannerForm";
import axios from "axios";
import _ from "lodash";
import { getToken } from "../../../utils/common";
import { Table, Space, Tooltip, Button, Popconfirm, notification } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { baseUrl } from "../../../utils/apiUrl";
import "./styles.css";

const PromotionBanners = (props) => {
  const { title, breadcrumb } = props;
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState([]);
  const [token, setToken] = useState("");
  const [bannerData, setBannerData] = useState([]);

  // Call API to get Promotional Banner Image when component is mounted
  useEffect(() => {
    setToken(getToken());
    if (!_.isEmpty(token)) {
      axios
        .get(`${baseUrl()}/banner`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setBannerData(response.data);
          setLoading((loading) => !loading);
        })
        .catch((error) => {
          if (error.response ? error.response.status === 401 : null) {
            authErrorNotification();
          } else if (error.request) {
            const messageTitle = "Image not found";
            const messageDescription =
              "The requested image is not in the server";
            errorNotification(messageTitle, messageDescription);
          } else {
            const messageTitle = "Error in request";
            const messageDescription =
              "Request not structured correctly, please contact support";
            errorNotification(messageTitle, messageDescription);
          }
        });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  // ------------------------------------------------ Dialog Event
  const onDialogShow = () => {
    setShowDialog(!showDialog);
  };

  const onDialogDismiss = () => {
    if (editMode === true) {
      setEditMode(!editMode);
    }
    setShowDialog(!showDialog);
  };

  const onDialogSubmit = (values, formData, editMode) => {
    editMode
      ? onActionEdit(values, formData)
      : onActionCreate(values, formData);
  };

  // ------------------------------------------------ CRUD Event
  const onActionCreate = (values, formData) => {
    onDialogDismiss();
    const token = getToken();
    onDialogDismiss();
    axios
      .post(`${baseUrl()}/banner`, formData, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(() => {
        const createSuccess = "New Event Created!";
        successNotification(createSuccess);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          authErrorNotification();
        } else if (error.request) {
          const messageTitle = "No response from server";
          const messageDescription = "Please contact the server administrator";
          errorNotification(messageTitle, messageDescription);
        } else {
          const messageTitle = "Error in request";
          const messageDescription =
            "Request not structured correctly, please contact support";
          errorNotification(messageTitle, messageDescription);
        }
      });
  };

  const onActionEdit = (values, formData) => {
    onDialogDismiss();
    const updateBannerUrl = `${baseUrl()}/banner/update/` + values.image_id;
    axios
      .post(updateBannerUrl, formData, {
        headers: {
          authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(() => {
        const editSuccess = "Promotion Banner updated successfully!";
        successNotification(editSuccess);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          authErrorNotification();
        } else if (error.request) {
          const messageTitle = "No response from server";
          const messageDescription = "Please contact the server administrator";
          errorNotification(messageTitle, messageDescription);
        } else {
          const messageTitle = "Error in request";
          const messageDescription =
            "Request not structured correctly, please contact support";
          errorNotification(messageTitle, messageDescription);
        }
      });
    onDialogDismiss();
  };

  const onActionDelete = (values) => {
    const removeBannerUrl = `${baseUrl()}/banner/delete/` + values.image_id;
    axios
      .post(
        removeBannerUrl,
        { token: `Bearer ${token}` },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(() => {
        const deleteSuccess = "Promotional Banner removed successfully!";
        successNotification(deleteSuccess);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // authErrorNotification();
          console.log("Server returned error 401 unauthorized");
        } else if (error.request) {
          const messageTitle = "No response from server";
          const messageDescription = "Please contact the server administrator";
          errorNotification(messageTitle, messageDescription);
        } else {
          const messageTitle = "Error in request";
          const messageDescription =
            "Request not structured correctly, please contact support";
          errorNotification(messageTitle, messageDescription);
        }
      });
  };

  // ------------------------------------------------- Success Handler
  const close = () => {
    axios
      .get(`${baseUrl()}/banner`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setBannerData(response.data);
        setLoading((loading) => !loading);
      })
      .catch((error) => {
        if (error.response ? error.response.status === 401 : null) {
          authErrorNotification();
        } else if (error.request) {
          const messageTitle = "No response from server";
          const messageDescription = "Please contact the server administrator";
          errorNotification(messageTitle, messageDescription);
        } else {
          const messageTitle = "Error in request";
          const messageDescription =
            "Request not structured correctly, please contact support";
          errorNotification(messageTitle, messageDescription);
        }
      });
  };

  const successNotification = (success) => {
    setLoading(!loading);
    const key = "success";
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key);
          close();
        }}
      >
        Confirm
      </Button>
    );
    notification.open({
      message: success,
      description: "Refetching new data from database......",
      btn,
      key,
      onClose: close,
      icon: <InfoCircleOutlined style={{ color: "#90ee90" }} />,
    });
  };

  // ------------------------------------------------- Error Handler
  const redirect = () => {
    sessionStorage.clear();
    window.location.replace("/");
  };

  const authErrorNotification = () => {
    const key = "authError";
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key);
          redirect();
        }}
      >
        Login
      </Button>
    );
    notification["error"]({
      message: "Authentication Expired!",
      description: "Please press the button below to login again",
      key,
      btn,
      onClose: redirect,
    });
  };

  const errorNotification = (messageTitle, messageDescription) => {
    const key = "error";
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key);
        }}
      >
        Ok
      </Button>
    );
    notification["error"]({
      message: messageTitle,
      description: messageDescription,
      key,
      btn,
    });
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditMode(!editMode);
                setEditData(record);
                onDialogShow();
              }}
            ></Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => {
                onActionDelete(record);
              }}
              okText="Yes"
              icon={<WarningOutlined style={{ color: "red" }} />}
            >
              <Button icon={<DeleteOutlined />} danger />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb} loading={loading}>
      <ActionButtons createBtnTitle="Add Banner" onShowDialog={onDialogShow} />
      <div className="static-page-banner-container">
        <Table
          columns={columns}
          dataSource={bannerData}
          bordered
          loading={loading}
          rowKey={(record) => record.image_id}
        />
        {editMode ? (
          <Dialog
            dialogTitle="Edit Banner"
            visibility={showDialog}
            onDismissDialog={onDialogDismiss}
          >
            <BannerForm
              editMode={editMode}
              onFormDismiss={onDialogDismiss}
              onFormSubmit={onDialogSubmit}
              editData={editData || []}
            />
          </Dialog>
        ) : (
          <Dialog
            dialogTitle="Upload Banner"
            visibility={showDialog}
            onDismissDialog={onDialogDismiss}
          >
            <BannerForm
              editMode={editMode}
              onFormDismiss={onDialogDismiss}
              onFormSubmit={onDialogSubmit}
            />
          </Dialog>
        )}
      </div>
    </PageLayout>
  );
};

export default PromotionBanners;
