import React from "react";
import UsersContainer from "../../containers/Users";

/*
  This page displays all users of the HOMEs Mobile Application.
  This is not to be confused with user page, which displays
  the individual information of user selected from users page
 */

const Users = () => {
  return (
    <div>
      <UsersContainer title="Users" breadcrumb="Users" />
    </div>
  );
};

export default Users;
