import React, { useState, useEffect } from "react";
import PageLayout from "../../components/Layout";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import { Table, Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getData } from "../../helper/api-calls";
import "./styles.css";

const Redemptions = (props) => {
  const { title, breadcrumb } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");

  // ------------------------------------------------ Redemption Data Initilization
  useEffect(() => {
    async function fetchData() {
      const url = "redemption";
      const response = await getData(url);
      if (!_.isEmpty(response)) {
        setData(response);
      }
      setLoading((loading) => !loading);
    }
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------------- Search Event
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handlerSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handlerReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handlerSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlerSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handlerReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // ------------------------------------------------ Table Columns
  const columns = [
    {
      title: "Manager Name",
      dataIndex: "ManagerName",
      key: "ManagerName",
      ...getColumnSearchProps("ManagerName"),
    },
    {
      title: "Customer Name",
      dataIndex: "UserName",
      key: "UserName",
      ...getColumnSearchProps("UserName"),
    },
    {
      title: "Redeemed Points",
      dataIndex: "redeemed_points",
      key: "redeemed_points",
      sorter: (a, b) => a.redeemed_points - b.redeemed_points,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Remaining Points",
      dataIndex: "points",
      key: "points",
      sorter: (a, b) => a.points - b.points,
      sortDirections: ["ascend", "descend"],
    },
    // {
    //   title: "Redemption Tier",
    //   dataIndex: "tier",
    //   key: "tier",
    //   ...getColumnSearchProps("tier"),
    // },
  ];

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb} loading={loading}>
      <div className="redemption-container">
        <Table
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.redemption_id}
          loading={loading}
          bordered
          pagination={{ position: ["topRight", "bottomRight"] }}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Records:</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>{_.size(data)}</Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </div>
    </PageLayout>
  );
};

export default Redemptions;
