import React from "react";
import RedemptionContainer from "../containers/Redemption";

const Redemption = () => {
  return (
    <div>
      <RedemptionContainer title="Redemptions" breadcrumb="Redemptions" />
    </div>
  );
};

export default Redemption;
