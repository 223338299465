/*
 ** API URLs are defined in this folder
 */

// get fallback image url when encountering 404 error
export const getImage404 = () => {
  let fallbackImage =
    "https://laravel.homeslivingapp.com/storage/app/public/errors/404.jpg";
  return fallbackImage;
};

export const getGiftImage = () => {
  let giftImage =
    "https://laravel.homeslivingapp.com/storage/app/public/staticpages/";
  return giftImage;
};

export const getStaticImage = () => {
  let staticImage =
    "https://laravel.homeslivingapp.com/storage/app/public/staticpages/";
  return staticImage;
};

const localUrl = "http://localhost:8000/api";
const productionUrl = "https://laravel.homeslivingapp.com/public/api";

// Read base url from env file, apply appropriate url based on environment (development || production)
export const baseUrl = () => {
  let url =
    process.env.NODE_ENV === "production"
      ? productionUrl
      : localUrl;
  return url;
};
