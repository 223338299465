import React from "react";
import LoginContainer from "../containers/Auth/Login";

const Login = (props) => {
  const { onLogin } = props;
  return (
    <div>
      <LoginContainer onLogin={onLogin} />
    </div>
  );
};

export default Login;
