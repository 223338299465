import React, { useState } from "react";
import { Image, Menu, Row, Col } from "antd";
import {
  UserOutlined,
  DashboardOutlined,
  CalendarOutlined,
  ShopOutlined,
  TeamOutlined,
  ShoppingOutlined,
  PayCircleOutlined,
  FileOutlined,
  FileTextOutlined,
  FileImageOutlined,
  FileProtectOutlined,
  InfoCircleOutlined,
  SafetyOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import homeImage from "../../../image/homes.png";
import "antd/dist/antd.css";
import "./styles.css";

// todo: Dynamically assign sidemenu items

const Sider = (props) => {
  const { SubMenu } = Menu;
  const { onCollapseSider, location, loading } = props;
  const [openKeys] = useState(["report", "redemption-mode"]);

  return (
    <div className="page-side-menu">
      <div className="logo">
        <Link to="/dashboard">
          <Row align="middle" justify={onCollapseSider ? "start" : "center"}>
            <Col span={24}>
              <Image src={homeImage} />
            </Col>
            {props.children}
          </Row>
        </Link>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        inlineIndent={12}
        defaultOpenKeys={openKeys}
      >
        <Menu.Item
          key="/dashboard"
          icon={<DashboardOutlined />}
          disabled={loading}
        >
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="/event" icon={<CalendarOutlined />} disabled={loading}>
          <Link to="/event">Events</Link>
        </Menu.Item>
        <Menu.Item key="/admin" icon={<UserOutlined />} disabled={loading}>
          <Link to="/admin">Admins</Link>
        </Menu.Item>
        <Menu.Item key="/manager" icon={<UserOutlined />} disabled={loading}>
          <Link to="/manager">Redemption Managers</Link>
        </Menu.Item>
        <Menu.Item key="/companies" icon={<ShopOutlined />} disabled={loading}>
          <Link to="/companies">Companies</Link>
        </Menu.Item>
        <Menu.Item key="/users" icon={<TeamOutlined />} disabled={loading}>
          <Link to="/users">Users(Visitors)</Link>
        </Menu.Item>
        <SubMenu key="staticpage" icon={<FileOutlined />} title="Static Pages">
          <Menu.Item
            key="/about"
            icon={<InfoCircleOutlined />}
            disabled={loading}
          >
            <Link to="/about">About Us</Link>
          </Menu.Item>
          <Menu.Item
            key="/termsconditions"
            icon={<FileProtectOutlined />}
            disabled={loading}
          >
            <Link to="/termsconditions">Terms & Conditions</Link>
          </Menu.Item>
          <Menu.Item
            key="/privacypolicy"
            icon={<SafetyOutlined />}
            disabled={loading}
          >
            <Link to="/privacypolicy">Privacy Policy</Link>
          </Menu.Item>
          <Menu.Item
            key="/homepagebanner"
            icon={<FileImageOutlined />}
            disabled={loading}
          >
            <Link to="/homepagebanner">Homepage Banner</Link>
          </Menu.Item>
          <Menu.Item
            key="/giftimage"
            icon={<FileImageOutlined />}
            disabled={loading}
          >
            <Link to="/giftimage">Gift Images</Link>
          </Menu.Item>
          <Menu.Item
            key="/eventinformation"
            icon={<FileImageOutlined />}
            disabled={loading}
          >
            <Link to="/eventinformation">Event Information</Link>
          </Menu.Item>
          <Menu.Item
            key="promotionbanner"
            icon={<FileImageOutlined />}
            disabled={loading}
          >
            <Link to="/promotionbanner">Promotion Banner</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="/notification"
          icon={<BellOutlined />}
          disabled={loading}
        >
          <Link to="/notification">Push Notifications</Link>
        </Menu.Item>
        <Menu.Item key="/order" icon={<ShoppingOutlined />} disabled={loading}>
          <Link to="/order">Orders</Link>
        </Menu.Item>
        <Menu.Item
          key="/redemption"
          icon={<PayCircleOutlined />}
          disabled={loading}
        >
          <Link to="/redemption">Redemptions</Link>
        </Menu.Item>
        <SubMenu key="report" icon={<FileOutlined />} title="Reports">
          <Menu.Item
            key="/eventReport"
            icon={<FileTextOutlined />}
            disabled={loading}
          >
            <Link to="/eventReport">Event Report</Link>
          </Menu.Item>
          <Menu.Item
            key="/companyReport"
            icon={<FileTextOutlined />}
            disabled={loading}
          >
            <Link to="/companyReport">Company Report</Link>
          </Menu.Item>
          <Menu.Item
            key="/customerReport"
            icon={<FileTextOutlined />}
            disabled={loading}
          >
            <Link to="/customerReport">Customer Report</Link>
          </Menu.Item>
          <Menu.Item
            key="/userDataReport"
            icon={<FileTextOutlined />}
            disabled={loading}
          >
            <Link to="/userDataReport">User Data Report</Link>
          </Menu.Item>
          <SubMenu
            key="redemption-mode"
            icon={<FileOutlined />}
            title="Redemption Reports"
          >
            <Menu.Item
              key="/completedRedemption"
              icon={<FileTextOutlined />}
              disabled={loading}
            >
              <Link to="/completedRedemption">Completed</Link>
            </Menu.Item>
            <Menu.Item
              key="/remainingRedemption"
              icon={<FileTextOutlined />}
              disabled={loading}
            >
              <Link to="/remainingRedemption">Zero Redemption</Link>
            </Menu.Item>
            <Menu.Item
              key="/zeroPurchase"
              icon={<FileTextOutlined />}
              disabled={loading}
            >
              <Link to="/zeroPurchase">Zero Purchase</Link>
            </Menu.Item>
          </SubMenu>
        </SubMenu>
      </Menu>
    </div>
  );
};

export default withRouter(Sider);
