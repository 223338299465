import React from "react";
import UserDataReportContainer from "../../containers/Report/UserDataReport";

/*
//  User Data Report page displays the total number of user's data,
//  based on the database columns in homes_users. Some columns are
//  not relevant, thus are omitted.
*/

const UserDataReport = () => {
  return (
    <div>
      <UserDataReportContainer
        title="User Data Report"
        breadcrumb="User Data Report"
      />
    </div>
  );
};

export default UserDataReport;
