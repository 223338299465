import React from "react";
import {
  Form,
  Input,
  InputNumber,
  Divider,
  Button,
  Row,
  Col,
  Select,
} from "antd";
import "./styles.css";

const CompanyForm = (props) => {
  const { Option } = Select;
  const { editData, editMode } = props;
  const { TextArea } = Input;
  const data = editData || [];

  const onActionDismiss = () => {
    if (props.onFormDismiss) {
      props.onFormDismiss();
    }
  };

  const onActionSubmit = (fieldsValue) => {
    let formData = new FormData();

    if (editMode) {
      appendUpdateData(fieldsValue, formData);
    } else {
      appendCreateData(fieldsValue, formData);
    }

    if (props.onFormSubmit) {
      props.onFormSubmit(fieldsValue, formData, editMode);
    }
  };

  // append data into a form for new data
  const appendCreateData = (fieldsValue, formData) => {
    formData.append("company_name", fieldsValue.company_name);
    formData.append("company_category", fieldsValue.company_category);
    formData.append("address", fieldsValue.address);
    formData.append("status", fieldsValue.status);
    formData.append("username", fieldsValue.username);
    formData.append("office_no", fieldsValue.office_no);
    formData.append("hp_no", fieldsValue.hp_no);
    formData.append("password", fieldsValue.password);
    formData.append("secondary_password", fieldsValue.secondary_password);
    formData.append("code", fieldsValue.code);
    formData.append("image", fieldsValue.image);
  };

  // append data into form data for updating data of existing company
  const appendUpdateData = (fieldsValue, formData) => {
    formData.append("company_name", fieldsValue.company_name);
    formData.append("company_category", fieldsValue.company_category);
    formData.append("address", fieldsValue.address);
    formData.append("office_no", fieldsValue.office_no);
    formData.append("hp_no", fieldsValue.hp_no);
    formData.append("status", fieldsValue.status);
    // formData.append("password", fieldsValue.password);
    // formData.append("secondary_password", fieldsValue.secondary_password);
    formData.append("code", fieldsValue.code);
    formData.append("image", fieldsValue.image);
  };

  return (
    <Form
      layout="vertical"
      preserve={false}
      onFinish={onActionSubmit}
      requiredMark={false}
    >
      {editMode ? (
        <Form.Item
          name="CompanyID"
          label="Company ID"
          initialValue={data.company_id || []}
        >
          <Input disabled />
        </Form.Item>
      ) : null}
      <Form.Item
        name="company_name"
        label="Company Name"
        initialValue={data.CompanyName || []}
        rules={[
          {
            required: true,
            message: "Company name cannot be blank!",
          },
        ]}
      >
        <Input placeholder="Please enter company name" />
      </Form.Item>
      <Form.Item
        name="company_category"
        label="Company Category"
        initialValue={data.company_categories || []}
        rules={[
          {
            required: true,
            message: "Please select the company's category",
          },
        ]}
      >
        <Select placeholder="Select a category">
          <Option value={1}>Kitchen Cabinet & Wardrobe</Option>
          <Option value={2}>Floor Finishes</Option>
          <Option value={3}>Garden & Landscape</Option>
          <Option value={4}>Gate/Awning</Option>
          <Option value={5}>Home Security System</Option>
          <Option value={6}>Smarthome System</Option>
          <Option value={7}>Switches</Option>
          <Option value={8}>Home Services</Option>
          <Option value={9}>Interior Design</Option>
          <Option value={10}>Kitchen & Bath</Option>
          <Option value={11}>Kitchenware & Houseware</Option>
          <Option value={12}>Swimming Pool</Option>
          <Option value={13}>Window, Door & Glass</Option>
          <Option value={14}>Art & Decorative</Option>
          <Option value={15}>Furniture & Furnishing</Option>
          <Option value={16}>AV & Home Appliances</Option>
          <Option value={17}>Household/Cash & Carry</Option>
          <Option value={18}>Kid Furniture</Option>
          <Option value={19}>Mattress & Bedding Set</Option>
          <Option value={20}>Insect & Security Screen</Option>
          <Option value={21}>Soft Furnishing</Option>
          <Option value={22}>Water Filtration & Solar</Option>
          <Option value={23}>Lighting</Option>
          <Option value={24}>Wall Finishes</Option>
          <Option value={25}>Others</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="address"
        label="Company Address"
        initialValue={data.address || []}
        rules={[
          {
            required: true,
            message: "Company Address cannot be blank!",
          },
        ]}
      >
        <TextArea placeholder="Please enter company address" rows={4} />
      </Form.Item>
      <Form.Item
        name="office_no"
        label="Company Contact"
        initialValue={data.office_no || []}
        rules={[{ required: true, message: "Office number cannot be blank" }]}
      >
        <Input placeholder="Please enter company phone" />
      </Form.Item>
      <Form.Item
        name="status"
        label="Company Status"
        rules={[
          {
            required: true,
            message: "Please select company status",
          },
        ]}
      >
        <Select placeholder="Please select company status">
          <Select.Option value={1}>ACTIVE</Select.Option>
          <Select.Option value={0}>DEFUNCT</Select.Option>
          <Select.Option value={""}>N/A</Select.Option>
        </Select>
      </Form.Item>
      {editMode ? null : (
        <Form.Item
          name="username"
          label="Company Email"
          initialValue={data.PIC || []}
          rules={[
            {
              required: true,
              message: "Company email cannot be blank!",
            },
            {
              type: "email",
              message: "Email above is not a valid email address",
            },
          ]}
        >
          <Input placeholder="Please enter company email" />
        </Form.Item>
      )}
      {editMode ? (
        <Form.Item
          name="hp_no"
          label="PIC Contact"
          initialValue={data.hp_no || []}
        >
          <Input placeholder="Please enter PIC contact" />
        </Form.Item>
      ) : (
        <Form.Item
          name="hp_no"
          label="PIC Contact"
          initialValue={data.hp_no || []}
        >
          <Input placeholder="Please enter PIC contact" />
        </Form.Item>
      )}
      {editMode ? (
        null
      ) : (
        <Form.Item
          name="password"
          label="First Password"
          rules={[
            {
              required: true,
              message: "First password cannot be blank!",
            },
            {
              min: 6,
              message: "Password length must be at least 6 or longer",
            },
          ]}
        >
          <Input.Password placeholder="Please enter first password" />
        </Form.Item>
      )}
      {editMode ? (
        null
      ) : (
        <Form.Item
          name="secondary_password"
          label="Second Password"
          rules={[
            {
              required: true,
              message: "Second password cannot be blank!",
            },
            {
              min: 6,
              message: "Password length must be at least 6 or longer",
            },
          ]}
        >
          <Input.Password placeholder="Please enter secondary password" />
        </Form.Item>
      )}
      <Form.Item
        name="code"
        label="Company Code"
        initialValue={data.CompanyCode || []}
        rules={[
          {
            required: true,
            message: "Company Code is required!",
          },
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Divider />
      <Row justify="end">
        <Col span={6} className="cancel-col">
          <Button size="large" block onClick={onActionDismiss}>
            Cancel
          </Button>
        </Col>
        <Col span={6}>
          <Button type="primary" size="large" block htmlType="submit">
            Confirm
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CompanyForm;
