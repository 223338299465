import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/Layout";
import Drawer from "../../../components/Drawer";
import CustomerReportDrawer from "../../../components/Drawer/CustomerReportDrawer";
import FilterForm from "../../../components/FilterForm";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import { getData } from "../../../helper/api-calls";
import { CSVLink } from "react-csv";
import { Table, Tooltip, Button, Space, Input, Row, Col } from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import "./styles.css";

const CustomerReports = (props) => {
  const { title, breadcrumb } = props;
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  // filter toggles filter form
  const [filter, setFilter] = useState(false);
  const [filtered, setFiltered] = useState(false);

  const [refetch, setRefetch] = useState(0);

  // ------------------------------------------------ CustomerReport Data Initialization
  useEffect(() => {
    async function fetchData() {
      const url = "customerReport";
      const response = await getData(url);
      if (!_.isEmpty(response)) {
        setLoading((loading) => !loading);
        processCustomerReportData(response);
      }
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // Process data returned from API,
  // remove first array results due to null values
  const processCustomerReportData = (data) => {
    //remove first data from array, due to null values
    let processedData = _.reject(data, function (el) {
      return el.user_id === null;
    });

    // spread the data array, round total amount
    // & total deposit to two decimal places
    var results = processedData.map((data) => ({
      ...data,
      Total_Amount: _.round(data.Total_Amount, 2),
      Total_Deposit: _.round(data.Total_Deposit, 2),
      created_at: data.created_at.substring(0, 10),
      month: data.created_at.substring(5, 7),
      year: data.created_at.substring(0, 4),
    }));
    setReportData(results);
  };

  // funcion to detect if the user clicked on Filter button
  const onFilter = () => {
    setFilter(!filter);
  };

  // ------------------------------------------------ Date Filter Form Submission
  const onActionSubmit = (values) => {
    setFiltered(!filtered);

    if (values.filter === "date") {
      var dateFilter = reportData.filter((record) => {
        return record.created_at.toString() === values.date.toString();
      });
      setReportData(dateFilter);
    } else if (values.filter === "month") {
      let value = values.date.substring(5, 7);
      var monthFilter = reportData.filter((record) => {
        return record.month.toString() === value.toString();
      });
      setReportData(monthFilter);
    } else if (values.filter === "year") {
      var yearFilter = reportData.filter((record) => {
        return record.year.toString() === values.date.toString();
      });
      setReportData(yearFilter);
    } else {
      var rangeFilter = reportData.filter((record) => {
        return (
          record.created_at.toString() >= values.date[0].toString() &&
          record.created_at.toString() <= values.date[1].toString()
        );
      });
      setReportData(rangeFilter);
    }
  };

  // Clear the date filter by refetching
  // complete set of data through API call
  const onActionClear = () => {
    setFilter(!filter);
    setFiltered(!filtered);
    setLoading(!loading);
    setRefetch(refetch + 1);
  };

  // ------------------------------------------------ Export CSV Event
  const reportColumns = (results) => {
    if (!_.isEmpty(results)) {
      const columns = Object.keys(results[0]);

      let headers = [];

      columns.forEach((col, index) => {
        if (col !== "month" && col !== "year") {
          headers.push({ label: col, key: col });
        }
      });

      return headers;
    }
  };

  // ------------------------------------------------ Drawer Event
  const onDrawerShow = () => {
    setShowDrawer(!showDrawer);
  };

  const onDrawerDismiss = () => {
    setShowDrawer(!showDrawer);
  };

  // ----------------------------------------------- Search Event
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handlerSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handlerReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handlerSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlerSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handlerReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // ------------------------------------------------ Table Columns
  const columns = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    // {
    //   title: "Race",
    //   dataIndex: "race",
    //   key: "race",
    //   ...getColumnSearchProps("race"),
    // },
    {
      title: "Hp No",
      dataIndex: "hp_no",
      key: "hp_no",
      ...getColumnSearchProps("hp_no"),
    },
    // {
    //   title: "Age",
    //   dataIndex: "age",
    //   key: "age",
    //   sorter: (a, b) => a.age - b.age,
    //   sortDirections: ["ascend", "descend"],
    // },
    // {
    //   title: "Religion",
    //   dataIndex: "religion",
    //   key: "religion",
    //   filters: [
    //     { text: "Christian", value: "Christian" },
    //     { text: "Muslim", value: "Muslim" },
    //     { text: "Buddhist", value: "Buddhist" },
    //     { text: "Others", value: "Others" },
    //   ],
    //   onFilter: (value, record) => record.religion.includes(value),
    // },
    // {
    //   title: "Area",
    //   dataIndex: "area",
    //   key: "area",
    //   ...getColumnSearchProps("area"),
    // },
    // {
    //   title: "Events Participated",
    //   dataIndex: "eventsParticipated",
    //   key: "eventsParticipated",
    //   sorter: (a, b) => a.eventsParticipated - b.eventsParticipated,
    //   sortDirections: ["ascend", "descend"],
    // },

    // {
    //   title: "Points Used",
    //   dataIndex: "pointsUsed",
    //   key: "pointsUsed",
    //   sorter: (a, b) => a.pointsUsed - b.pointsUsed,
    //   sortDirections: ["ascend", "descend"],
    // },
    {
      title: "Total Spent(RM)",
      dataIndex: "total_amount",
      key: "total_amount",
      sorter: (a, b) => a.total_amount - b.total_amount,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Points",
      dataIndex: "total_deposit",
      key: "total_deposit",
      sorter: (a, b) => a.total_deposit - b.total_deposit,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Redeemed Points",
      dataIndex: "total_redeemed_points",
      key: "total_redeemed_points",
      sorter: (a, b) => a.total_redeemed_points - b.total_redeemed_points,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Remaining Points",
      dataIndex: "total_remaining_points",
      key: "total_remaining_points",
      sorter: (a, b) => a.total_remaining_points - b.total_remaining_points,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                setDrawerData(record);
                onDrawerShow();
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb}>
      <div className="customer-report-container">
        <Row justify="start">
          <Col className="action-col" span={3}>
            <Button type="primary" onClick={onFilter} disabled={loading}>
              Date Filter
            </Button>
          </Col>
          <Col className="action-col" span={5}>
            <CSVLink
              data={reportData}
              headers={reportColumns(reportData)}
              filename={"CustomerReport.csv"}
            >
              <Button type="primary" disabled={loading}>
                Export As CSV
              </Button>
            </CSVLink>
          </Col>
        </Row>

        {filter ? (
          <FilterForm
            onFormSubmit={onActionSubmit}
            onFormClear={onActionClear}
            onDataFiltered={filtered}
          />
        ) : null}
        <Table
          columns={columns}
          dataSource={reportData || []}
          loading={loading}
          rowKey={(record) => record.user_id}
          bordered
          pagination={{ position: ["topRight", "bottomRight"] }}
          scroll={{ x: 800 }}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Records:</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {_.size(reportData)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
        <Drawer
          visibility={showDrawer}
          onClose={onDrawerDismiss}
          drawerTitle="Customer Details"
          drawerWidth="900px"
          destroyOnClose={true}
        >
          <CustomerReportDrawer dataSource={drawerData} />
        </Drawer>
      </div>
    </PageLayout>
  );
};

export default CustomerReports;
