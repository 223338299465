import React from "react";
import CustomerReportContainer from "../../containers/Report/CustomerReport";

const CustomerReport = () => {
  return (
    <div>
      <CustomerReportContainer
        title="Customer Report"
        breadcrumb="Customer Report"
      />
    </div>
  );
};

export default CustomerReport;
