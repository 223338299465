import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/Layout";
import axios from "axios";
import _ from "lodash";
import { getToken } from "../../../utils/common";
import {
  Input,
  Form,
  Button,
  Divider,
  notification,
  Row,
  Col,
  Image,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { baseUrl } from "../../../utils/apiUrl";
import "./styles.css";

const GiftImages = (props) => {
  const { title, breadcrumb } = props;
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [disableUpload, setDisableUpload] = useState(true);
  const [form] = Form.useForm();

  let giftImageUrl = "";

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  // ----------------------------------------------------- Data Initialization
  useEffect(() => {
    setToken(getToken());
    if (!_.isEmpty(token)) {
      axios
        .get(`${baseUrl()}/gift_image`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setLoading((loading) => !loading);
          onSetInitialValue(response.data);
        })
        .catch((error) => {
          if (error.response ? error.response.status === 401 : null) {
            authErrorNotification();
          } else if (error.request) {
            const messageTitle = "No response from server";
            const messageDescription =
              "Please contact the server administrator";
            errorNotification(messageTitle, messageDescription);
          } else {
            const messageTitle = "Error in request";
            const messageDescription =
              "Request not structured correctly, please contact support";
            errorNotification(messageTitle, messageDescription);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const onActionEdit = (values, formData) => {
    axios
      .post(`${baseUrl()}/gift_image/update/` + values.cms_id, formData, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(() => {
        const createSuccess = "Homepage Banner Uploaded Successfully!";
        successNotification(createSuccess);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          authErrorNotification();
        } else if (error.request) {
          const messageTitle = "No response from server";
          const messageDescription = "Please contact the server administrator";
          errorNotification(messageTitle, messageDescription);
        } else {
          const messageTitle = "Error in request";
          const messageDescription =
            "Request not structured correctly, please contact support";
          errorNotification(messageTitle, messageDescription);
        }
      });
  };

  // ------------------------------------------------- Success Handler
  const close = () => {
    axios
      .get(`${baseUrl()}/gift_image`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setLoading((loading) => !loading);
        onSetInitialValue(response.data);
      })
      .catch((error) => {
        if (error.response ? error.response.status === 401 : null) {
          authErrorNotification();
        } else if (error.request) {
          const messageTitle = "No response from server";
          const messageDescription = "Please contact the server administrator";
          errorNotification(messageTitle, messageDescription);
        } else {
          const messageTitle = "Error in request";
          const messageDescription =
            "Request not structured correctly, please contact support";
          errorNotification(messageTitle, messageDescription);
        }
      });
  };

  const successNotification = (success) => {
    setLoading(!loading);
    const key = "success";
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key);
          close();
        }}
      >
        Confirm
      </Button>
    );
    notification.open({
      message: success,
      description: "Refetching new data from database......",
      btn,
      key,
      onClose: close,
      icon: <InfoCircleOutlined style={{ color: "#90ee90" }} />,
    });
  };

  // ------------------------------------------------- Error Handler
  const redirect = () => {
    sessionStorage.clear();
    window.location.replace("/");
  };

  const authErrorNotification = () => {
    const key = "authError";
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key);
          redirect();
        }}
      >
        Login
      </Button>
    );
    notification["error"]({
      message: "Authentication Expired!",
      description: "Please press the button below to login again",
      key,
      btn,
      onClose: redirect,
    });
  };

  const errorNotification = (messageTitle, messageDescription) => {
    const key = "error";
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key);
        }}
      >
        Ok
      </Button>
    );
    notification["error"]({
      message: messageTitle,
      description: messageDescription,
      key,
      btn,
    });
  };

  // ---------------------------------------------------------- Form Submission Event

  // append the file into selectedFile
  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setDisableUpload(!disableUpload);
  };

  const onActionSubmit = (values) => {
    let formData = new FormData();
    formData.append("page_name", values.page_name);
    formData.append("image", selectedFile);

    onActionEdit(values, formData);
  };

  // ---------------------------------------------------- Form Set Initial Value Event

  // set the initial value of page name form item & image
  const onSetInitialValue = (response) => {
    // set the initial value of form item
    form.setFieldsValue({
      cms_id: response.cms_id ? response.cms_id : "",
    });
    form.setFieldsValue({
      page_name: response.page_name ? response.page_name : "",
    });
    giftImageUrl = response.image;
  };

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb} loading={loading}>
      <div className="brandimage-container">
        <Image
          className="gift-image"
          src={giftImageUrl}
          fallback="fallback error"
        />
        <Form
          form={form}
          name="gift-image-form"
          onFinish={onActionSubmit}
          initialValues={{ remember: true }}
        >
          <Form.Item
            {...formItemLayout}
            name="cms_id"
            label="Page ID"
            rules={[
              {
                required: true,
                message: "Page ID cannot be blank",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="page_name"
            label="Page Name"
            rules={[
              {
                required: true,
                message: "Page name cannot be blank",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="image"
            label="Upload Image"
            rules={[
              {
                required: true,
                message: "No file chosen to upload",
              },
            ]}
          >
            <input
              type="file"
              onChange={fileSelectedHandler}
              accept="image/png, image/jpg, image/jpeg"
            />
          </Form.Item>
          <Divider />
          <Row justify="start">
            <Col>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                disabled={disableUpload}
              >
                Upload
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </PageLayout>
  );
};

export default GiftImages;
