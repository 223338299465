import React from "react";
import PrivacyPolicyContainer from "../../containers/StaticPage/PrivacyPolicy";

const PrivacyPolicy = () => {
  return (
    <div>
      <PrivacyPolicyContainer
        title="Privacy Policy"
        breadcrumb="Privacy Policy"
      />
    </div>
  );
};

export default PrivacyPolicy;
