import React from "react";
import GiftImageContainer from "../../containers/StaticPage/GiftImage";

const GiftImage = () => {
  return (
    <div>
      <GiftImageContainer title="Gift Image" breadcrumb="Gift Image" />
    </div>
  );
};

export default GiftImage;
