import React, { useState, useEffect } from "react";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import Drawer from "../../../components/Drawer";
import CustomerOrdersDrawer from "./CustomerOrdersDrawer";
import { Row, Col, Divider, Table, Input, Space, Button, Tooltip } from "antd";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { getSpecifiedData } from "../../../helper/api-calls";
import "./styles.css";

const CustomerReportDrawer = (props) => {
  const DescriptionItem = ({ title, content }) => (
    <div className="description-item-wrapper">
      <Row justify="start" align="top">
        <Col span={8}>
          <p className="description-item-title">{title}:</p>
        </Col>
        <Col span={12}>{content}</Col>
      </Row>
    </div>
  );

  const { dataSource } = props;
  const data = dataSource || [];
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [customerEventData, setCustomerEventData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState();

  // Process amount & deposit to two decimal places
  const processCustomerData = (data) => {
    var results = data.map((data) => ({
      ...data,
      amount: _.round(data.amount, 2),
      deposit_amount: _.round(data.deposit_amount, 2),
    }));
    setCustomerEventData(results);
  };

  // ------------------------------------------------ API Calls
  // Get all orders by the customer
  useEffect(() => {
    async function fetchData() {
      const url = `txnAll?user_id=` + data.user_id;
      const response = await getSpecifiedData(url);
      if (!_.isEmpty(response)) {
        processCustomerData(response);
      }
      setLoading((loading) => !loading);
    }
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------------- Search Event
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handlerSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handlerReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handlerSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlerSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handlerReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // ------------------------------------------------ Table Columns
  const columns = [
    {
      title: "Event Title",
      dataIndex: "EventTitle",
      key: "EventTitle",
      ...getColumnSearchProps("EventTitle"),
    },
    {
      title: "Company",
      dataIndex: "CompanyName",
      key: "CompanyName",
      ...getColumnSearchProps("CompanyName"),
    },
    {
      title: "Sales Person",
      dataIndex: "sales_person",
      key: "sales_person",
      ...getColumnSearchProps("sales_person"),
    },
    {
      title: "Category",
      dataIndex: "remarks",
      key: "remarks",
      filters: [
        { text: "Furniture", value: "Furniture" },
        { text: "Cash & Carry", value: "Cash & Carry" },
        { text: "Electrical", value: "Electrical" },
        { text: "Renovation", value: "Renovation" },
        { text: "Kitchen & Bath", value: "Kitchen & Bath" },
      ],
      onFilter: (value, record) =>
        record.Category ? record.Category.includes(value) : [],
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    // {
    //   title: "Order Status",
    //   dataIndex: "status",
    //   key: "status",
    //   filters: [
    //     { text: "Confirmed", value: "Confirmed" },
    //     { text: "Cancelled", value: "Cancelled" },
    //   ],
    //   onFilter: (value, record) => record.status.includes(value),
    // },
    {
      title: "Amount Spent",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Deposit",
      dataIndex: "deposit_amount",
      key: "deposit_amount",
      sorter: (a, b) => a.deposit_amount - b.deposit_amount,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                setDrawerData(record);
                onDrawerShow();
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  // ------------------------------------------------ Drawer Event
  const onDrawerShow = () => {
    setShowDrawer(!showDrawer);
  };

  const onDrawerDismiss = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <div className="user-drawer-container">
      <Row>
        <Col span={12}>
          <DescriptionItem title="Customer Name" content={data.name || []} />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Total Spent(RM)"
            content={Number(data.total_amount).toLocaleString("en") || []}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Total Deposit(RM)"
            content={Number(data.total_deposit).toLocaleString("en") || []}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Total Orders"
            content={data.total_purchase || []}
          />
        </Col>
      </Row>
      <Divider />
      <p className="section-title">Customer Order Details</p>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={customerEventData || []}
            bordered
            rowKey={(record) => record.order_id}
            loading={loading}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  Total Records:
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {_.size(customerEventData)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
      </Row>
      <Drawer
        visibility={showDrawer}
        onClose={onDrawerDismiss}
        drawerTitle="Customer Order Details"
        destroyOnClose={true}
      >
        <CustomerOrdersDrawer ordersData={drawerData} />
      </Drawer>
      <Divider />
    </div>
  );
};

export default CustomerReportDrawer;
