import React from "react";
import { Form, Input, InputNumber, Divider, Button, Row, Col } from "antd";
import "./styles.css";

const OrderForm = (props) => {
  const { editData, editMode } = props;

  const onActionDismiss = () => {
    if (props.onFormDismiss) {
      props.onFormDismiss();
    }
  };

  const onActionSubmit = (values) => {
    let formData = new FormData();

    if (editMode) {
      appendUpdateData(values, formData);
    }

    if (props.onFormSubmit) {
      props.onFormSubmit(values, formData, editMode);
    }
  };

  // append data into form data for updating data of existing company
  const appendUpdateData = (values, formData) => {
    formData.append("salesPerson", values.salesPerson);
    formData.append("amount", values.amount);
    formData.append("deposit", values.deposit);
  };

  return (
    <Form
      layout="vertical"
      preserve={false}
      onFinish={onActionSubmit}
      requiredMark={false}
    >
      <Form.Item
        name="order_id"
        label="Order ID"
        initialValue={editData ? editData.order_id || null : []}
      >
        <InputNumber disabled />
      </Form.Item>
      <Form.Item
        name="salesPerson"
        label="Sales Person"
        initialValue={editData ? editData.sales_person || null : []}
      >
        <Input placeholder="Sales Person cannot be blank" min={5} />
      </Form.Item>
      <Form.Item
        name="amount"
        label="Amount Spent"
        initialValue={editData ? editData.amount || null : []}
        rules={[
          {
            required: true,
            message: "Amount spent cannot be blank",
          },
          {
            type: "integer",
            message: "Amount only accept numbers",
          },
        ]}
      >
        <InputNumber placeholder="Amount" min={1} />
      </Form.Item>
      <Form.Item
        name="deposit"
        label="Deposit Amount"
        initialValue={editData ? editData.deposit_amount || null : []}
        rules={[
          {
            required: true,
            message: "Deposit cannot be blank!",
          },
          {
            type: "integer",
            message: "Deposit amount only accept numbers",
          },
        ]}
      >
        <InputNumber placeholder="Deposit" min={1} />
      </Form.Item>
      <Divider />
      <Row justify="end">
        <Col span={6} className="cancel-col">
          <Button size="large" block onClick={onActionDismiss}>
            Cancel
          </Button>
        </Col>
        <Col span={6}>
          <Button type="primary" size="large" block htmlType="submit">
            Confirm
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default OrderForm;
