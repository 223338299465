import React from "react";
import TermsConditionsContainer from "../../containers/StaticPage/TermsConditions";

const TermsCondition = () => {
  return (
    <div>
      <TermsConditionsContainer
        title="Terms & Conditions"
        breadcrumb="Terms & Conditions"
      />
    </div>
  );
};

export default TermsCondition;
