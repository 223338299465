import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/Layout";
import Drawer from "../../../components/Drawer";
import CustomerReportDrawer from "../../../components/Drawer/CustomerReportDrawer";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { Table, Button, Space, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { getData } from "../../../helper/api-calls";
import "./styles.css";

const User = (props) => {
  const { title, nestedCrumb, link } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  let { id } = useParams();
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState([]);

  // ------------------------------------------------ User Data Initialization
  useEffect(() => {
    async function fetchData() {
      const url = `getUser?id=` + id;
      const response = await getData(url);
      if (!_.isEmpty(response)) {
        processData(response);
      }
      setLoading((loading) => !loading);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Format any monetary data into two decimal places
  const processData = (data) => {
    let processedData = data.map((data) => ({
      ...data,
      totalAmount: _.round(data.totalAmount, 2),
      totalDeposit: _.round(data.totalDeposit, 2),
    }));
    setData(processedData);
  };

  // ------------------------------------------------ Drawer Event
  const onDrawerShow = () => {
    setShowDrawer(!showDrawer);
  };

  const onDrawerDismiss = () => {
    setShowDrawer(!showDrawer);
  };

  // ------------------------------------------------ Table Columns
  const columns = [
    {
      title: "User name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Total Spent(RM)",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Deposit(RM)",
      dataIndex: "total_deposit",
      key: "total_deposit",
      render: (value) => {
        return <span>{Number(value).toLocaleString("en")}</span>;
      },
    },
    {
      title: "Total Orders",
      dataIndex: "total_purchase",
      key: "total_purchase",
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                setDrawerData(record);
                onDrawerShow();
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <PageLayout
      headerTitle={title}
      nestedCrumb={nestedCrumb}
      breadcrumb={id}
      link={link}
      loading={loading}
    >
      <div className="user-container">
        <Table
          columns={columns}
          dataSource={data || []}
          loading={loading}
          rowKey={(record) => record.user_id}
          bordered
        ></Table>
        <Drawer
          visibility={showDrawer}
          onClose={onDrawerDismiss}
          drawerTitle="User Order Details"
          drawerWidth="900px"
          destroyOnClose={true}
        >
          <CustomerReportDrawer dataSource={drawerData} />
        </Drawer>
      </div>
    </PageLayout>
  );
};

export default User;
