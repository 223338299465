import React, { useEffect, useState } from "react";
import PageLayout from "../../components/Layout";
import ActionButtons from "../../components/ActionButtons";
import Dialog from "../../components/Dialog";
import EventForm from "../../components/EventForm";
import Drawer from "../../components/Drawer";
import EventDrawer from "../../components/Drawer/EventDrawer";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import { Table, Space, Button, Tooltip, Popconfirm, Input } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  getData,
  storeData,
  updateData,
  deleteData,
} from "../../helper/api-calls";
import "./styles.css";

const Events = (props) => {
  const { title, breadcrumb } = props;
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState();
  const [editMode, setEditMode] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [editData, setEditData] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchColumn] = useState("");

  // state to toggle useEffect to refetch data after CUD actions
  const [refetch, setRefetch] = useState(0);

  // ------------------------------------------------ Event Data Initilization
  useEffect(() => {
    async function fetchData() {
      const url = "event";
      const response = await getData(url);
      if (!_.isEmpty(response)) {
        setEventData(response);
      }
      setLoading((loading) => !loading);
    }
    fetchData();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // ------------------------------------------------ Dialog Event
  const onDialogShow = () => {
    setShowDialog(!showDialog);
  };

  const onDialogDismiss = () => {
    if (editMode === true) {
      setEditMode(!editMode);
    }
    setShowDialog(!showDialog);
  };

  const onDialogSubmit = (values, formData, editMode) => {
    editMode
      ? onActionEdit(values, formData)
      : onActionCreate(values, formData);
  };

  // ------------------------------------------------ Drawer Event
  const onDrawerShow = () => {
    setShowDrawer(!showDrawer);
  };

  const onDrawerDismiss = () => {
    setShowDrawer(!showDrawer);
  };

  // ----------------------------------------------- Search Event
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handlerSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handlerReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handlerSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlerSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handlerReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // ------------------------------------------------ CRUD Event
  const onActionCreate = (values, formData) => {
    onDialogDismiss();
    const url = "event";
    storeData(url, formData);
    setLoading((loading) => !loading);
    setRefetch(refetch + 1);
  };

  const onActionEdit = (values, formData) => {
    const url = `event/update/` + values.exhibition_id;
    onDialogDismiss();
    updateData(url, formData);
    setLoading((loading) => !loading);
    setRefetch(refetch + 1);
  };

  /*
  //  The delete function merely changes the status of the event,
  //  from active to inactive (1 = active, 2 = inactive) in database,
  //  as the user requested.
  */
  const onActionDelete = (values) => {
    const url = "event/delete/" + values.exhibition_id;
    setLoading(!loading);
    deleteData(url);
    setRefetch(refetch + 1);
  };

  // ------------------------------------------------ Table Columns
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title"),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      ...getColumnSearchProps("location"),
    },
    {
      title: "Date Start",
      dataIndex: "StartDate",
      key: "StartDate",
      ...getColumnSearchProps("StartDate"),
    },
    {
      title: "Date End",
      dataIndex: "EndDate",
      key: "EndDate",
      ...getColumnSearchProps("EndDate"),
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                setDrawerData(record);
                onDrawerShow();
              }}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditMode(!editMode);
                setEditData(record);
                onDialogShow();
              }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Sure to delete this event?"
              onConfirm={() => {
                onActionDelete(record);
              }}
              okText="Yes"
              icon={<WarningOutlined style={{ color: "red" }} />}
            >
              <Button icon={<DeleteOutlined />} danger />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <PageLayout headerTitle={title} breadcrumb={breadcrumb} loading={loading}>
      <ActionButtons
        createBtnTitle="Create Event"
        onShowDialog={onDialogShow}
      />
      <div className="event-container">
        <Table
          columns={columns}
          dataSource={eventData}
          bordered
          loading={loading}
          rowKey={(record) => record.exhibition_id}
          pagination={{ position: ["topRight", "bottomRight"] }}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Records:</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {_.size(eventData)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
        {editMode ? (
          <Dialog
            dialogTitle={"Edit Event"}
            visibility={showDialog}
            onDismissDialog={onDialogDismiss}
          >
            <EventForm
              editMode={editMode}
              onFormDismiss={onDialogDismiss}
              onFormSubmit={onDialogSubmit}
              editData={editData || null}
            />
          </Dialog>
        ) : (
          <Dialog
            dialogTitle={"Create New Event"}
            visibility={showDialog}
            onDismissDialog={onDialogDismiss}
          >
            <EventForm
              editMode={editMode}
              onFormDismiss={onDialogDismiss}
              onFormSubmit={onDialogSubmit}
            />
          </Dialog>
        )}
        <Drawer
          visibility={showDrawer}
          onClose={onDrawerDismiss}
          drawerTitle="Event Details"
          drawerWidth="900px"
          destroyOnClose={true}
        >
          <EventDrawer dataSource={drawerData || []} />
        </Drawer>
      </div>
    </PageLayout>
  );
};

export default Events;
