import React from "react";
import CompletedRedemptionContainer from "../../containers/Report/RedemptionReport/CompletedRedemption";

// This page is part of Redemption Report, it displays Users that have Orders
// and made gift redemptions

const CompletedRedemptionReport = () => {
  return (
    <div>
      <CompletedRedemptionContainer
        title="Completed Redemption"
        breadcrumb="Completed Redemption"
      />
    </div>
  );
};

export default CompletedRedemptionReport;
