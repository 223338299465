import React from "react";
import { Form, Input, Divider, Button, Row, Col } from "antd";
import "./styles.css";

const ManagerForm = (props) => {
  const { editData, editMode } = props;

  const onActionDismiss = () => {
    if (props.onFormDismiss) {
      props.onFormDismiss();
    }
  };

  const onActionSubmit = (values) => {
    let formData = new FormData();

    if (editMode) {
      appendUpdateData(values, formData);
    } else {
      appendCreateData(values, formData);
    }

    if (props.onFormSubmit) {
      props.onFormSubmit(values, formData, editMode);
    }
  };

  const appendCreateData = (values, formData) => {
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("userType", values.userType);
  };

  // append data into form data for updating data of existing company
  const appendUpdateData = (values, formData) => {
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("userType", values.userType);
  };

  return (
    <Form
      layout="vertical"
      preserve={false}
      onFinish={onActionSubmit}
      requiredMark={false}
    >
      {editMode ? (
        <Form.Item
          name="manager_id"
          label="ID"
          initialValue={editData ? editData.manager_id || null : []}
        >
          <Input disabled />
        </Form.Item>
      ) : null}
      <Form.Item
        name="name"
        label="Name"
        initialValue={editData ? editData.name || null : []}
        rules={[
          {
            required: true,
            message: "Manager name cannot be blank!",
          },
        ]}
      >
        <Input placeholder="Please enter manager name" />
      </Form.Item>
      <Form.Item
        name="email"
        label="email"
        initialValue={editData ? editData.email || null : []}
        rules={[
          {
            required: true,
            message: "Email cannot be blank!",
          },
          {
            type: "email",
            message: "Please enter a valid email address",
          },
        ]}
      >
        <Input placeholder="Please enter manager email" />
      </Form.Item>
      {editMode ? null : (
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Password cannot be blank!",
            },
            {
              min: 6,
              message: "Password must be 6 characters or more",
            },
          ]}
        >
          <Input.Password placeholder="Please enter manager password" />
        </Form.Item>
      )}
      <Form.Item
        name="userType"
        label="Account Type"
        initialValue="redemptionManager"
      >
        <Input disabled />
      </Form.Item>
      <Divider />
      <Row justify="end">
        <Col span={6} className="cancel-col">
          <Button size="large" block onClick={onActionDismiss}>
            Cancel
          </Button>
        </Col>
        <Col span={6}>
          <Button type="primary" size="large" block htmlType="submit">
            Confirm
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ManagerForm;
