import React from "react";
import NotificationContainer from "../containers/Notification";

const Notification = () => {
  return (
    <div>
      <NotificationContainer
        title="Push Notification"
        breadcrumb="Push Notification"
      />
    </div>
  );
};

export default Notification;
