import React from "react";
import HomepageBannerContainer from "../../containers/StaticPage/HomepageBanner";

const HomepageBanner = () => {
  return (
    <div>
      <HomepageBannerContainer
        title="Homepage Banner"
        breadcrumb="Homepage Banner"
      />
    </div>
  );
};

export default HomepageBanner;
