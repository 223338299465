import React from "react";
import { Form, Input, Divider, Row, Col, Button } from "antd";
import "./styles.css";

const StaticPageForm = (props) => {
  const { pageContent, pageName } = props;
  const { TextArea } = Input;

  const onActionDismiss = () => {
    if (props.onFormDismiss) {
      props.onFormDismiss();
    }
  };

  const onActionSubmit = (fieldsValue) => {
    if (props.onFormSubmit) {
      props.onFormSubmit(fieldsValue);
    }
  };

  return (
    <Form layout="vertical" onFinish={onActionSubmit} requiredMark={false}>
      <Form.Item
        name="page_name"
        label="Page Name"
        initialValue={pageName}
        rules={[{ required: true, message: "Page name cannot be empty" }]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="content"
        label="Page Content"
        initialValue={pageContent}
        rules={[{ required: true, message: "Page content cannot be empty!" }]}
      >
        <TextArea rows={25} disabled />
      </Form.Item>
      <Form.Item name="image" label="image" initialValue="" hidden>
        <Input disabled value="" />
      </Form.Item>
      <Divider />
      <Row justify="end">
        <Col span={6} className="cancel-col">
          <Button size="large" block onClick={onActionDismiss}>
            Cancel
          </Button>
        </Col>
        <Col span={6}>
          <Button type="primary" size="large" block htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default StaticPageForm;
