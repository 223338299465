import React from "react";
import RemainingRedemptionContainer from "../../containers/Report/RedemptionReport/RemainingRedemption";

// This page is part of Redemption Report, it displays Users that have Orders
// but no gift redemptions yet.

const RemainingRedemptionReport = () => {
  return (
    <div>
      <RemainingRedemptionContainer
        title="Remaining Redemption"
        breadcrumb="Remaining Redemption"
      />
    </div>
  );
};

export default RemainingRedemptionReport;
