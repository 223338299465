import React from "react";
import AboutUsContainer from "../../containers/StaticPage/About";

const AboutUs = () => {
  return (
    <div>
      <AboutUsContainer title="About Us" breadcrumb="About Us" />
    </div>
  );
};

export default AboutUs;
